import React, { useState, useContext, useEffect, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { formatDate } from '../Utils/utils'

export default function AddUser() {
    const [state, setState] = useState({
        username: '',
        password: '',
        fname: '',
        lname: '',
        status: '',
        role: '',
        customer_number: '',
        exp_date: ''
    })
    //const [original, setOriginal] = useState(null)
    const [portals, setPortals] = useState(null);
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    // DOM References
    const refUsername = useRef();
    const refPassword = useRef();
    const refFirstName = useRef();
    const refLastName = useRef();
    const refRole = useRef();
    const refStatus = useRef();
    const refExpDate = useRef();
    const refReturnTop = useRef();
    const refSubmit = useRef();
    const refPortal = useRef();
    const refPortalError = useRef();

    // DOM Error References
    const refUsernameError = useRef();
    const refPasswordError = useRef();
    const refFirstNameError = useRef();
    const refLastNameError = useRef();
    const refRoleError = useRef();
    const refStatusError = useRef();
    const refExpDateError = useRef();

    // Heading Modal References
    const overlay = useRef();
    const popup = useRef();
    const modalHeading = useRef();
    const modalContent = useRef();
    const modalClose = useRef();

    // Handler for all input and textarea elements
    const handleChange = e => {
        const { name, value } = e.target;
        if(name === 'customer_number'){
            let split = state.customer_number.split(',');
            let toAdd = true;
            for(let a=0;a<split.length;a++){
                if(split[a] === value){
                    toAdd = false;
                }
            }

            if(toAdd){
                split.push(value)
                let newList = [];
                for(let a=0;a<portals.length;a++){
                    for(let b=0;b<split.length;b++){
                        if(portals[a].customer_number == split[b]){
                            newList.push(split[b])
                        }
                    }
                }

                setState(prevState => ({
                    ...prevState,
                    [name]: newList.join(',')
                }));    
            }
        }else{
        
            setState(prevState => ({
                ...prevState,
                //[name]: value ? value : null
                [name]: value
            }));
        }
    };

    async function handleSubmit(){
        let shouldSubmit = true;

        refUsername.current.classList.remove('error-outline');
        refUsernameError.current.style.display='none';
        refPassword.current.classList.remove('error-outline');
        refPasswordError.current.style.display='none';
        refFirstName.current.classList.remove('error-outline');
        refFirstNameError.current.style.display='none';
        refLastName.current.classList.remove('error-outline');
        refLastNameError.current.style.display='none';
        refRole.current.classList.remove('error-outline');
        refRoleError.current.style.display='none';
        refStatus.current.classList.remove('error-outline');
        refStatusError.current.style.display='none';
        refExpDate.current.classList.remove('error-outline');
        refExpDateError.current.style.display='none';
        refPortal.current.classList.remove('error-outline');
        refPortalError.current.style.display='none';

        if(state.username === ''){
            refUsernameError.current.innerHTML = 'Please enter an email address.';
            refUsername.current.classList.add('error-outline');
            refUsernameError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.username !== '' && state.username !== null){
            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if(!state.username.match(regex)){
                refUsernameError.current.innerHTML = 'Please enter a valid email address.';
                refUsername.current.classList.add('error-outline');
                refUsernameError.current.style.display='block';  
                shouldSubmit = false;
            } 
        }

        if(state.password === ''){
            refPasswordError.current.innerHTML = 'Please enter a password.';
            refPassword.current.classList.add('error-outline');
            refPasswordError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.fname === ''){
            refFirstNameError.current.innerHTML = 'Please enter a first name.';
            refFirstName.current.classList.add('error-outline');
            refFirstNameError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.lname === ''){
            refLastNameError.current.innerHTML = 'Please enter a last name.';
            refLastName.current.classList.add('error-outline');
            refLastNameError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.role.indexOf('Select') !== -1 || state.role === ''){
            refRoleError.current.innerHTML = 'Please select a role.';
            refRole.current.classList.add('error-outline');
            refRoleError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.status.indexOf('Select') !== -1 || state.status === ''){
            refStatusError.current.innerHTML = 'Please select a status.';
            refStatus.current.classList.add('error-outline');
            refStatusError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.exp_date !== ''){
            if(new Date(state.exp_date) <= new Date()){
                refExpDateError.current.innerHTML = 'Date must be at least 1 day in the future.';
                refExpDate.current.classList.add('error-outline');
                refExpDateError.current.style.display = 'block';
                shouldSubmit = false;
            }
        }

        // Portal Validation - None selected
        if(state.customer_number.split(',')[0] === '' && state.role !== 'admin'){
            refPortal.current.classList.add('error-outline');
            refPortalError.current.innerHTML = 'Please select a portal.';
            refPortalError.current.style.display='block';
            shouldSubmit = false;
        }

        // Portal Validation - External should only have one portal
        if(state.role === 'external'){
            if(state.customer_number.split(',').length > 1){
                refPortal.current.classList.add('error-outline');
                refPortalError.current.innerHTML = "Only 1 portal may be assigned to users with 'external' role.";
                refPortalError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        let response;
        let data;
        
        if(shouldSubmit){
            let requestObj = {
                username: state.username,
                password: state.password,
                fname: state.fname,
                lname: state.lname,
                role: state.role,
                status: state.status,
                customer_number: state.customer_number,
            }

            if(state.role === 'admin'){
                requestObj.customer_number = '1';
            }
            
            if(state.status === 'active'){
                if(new Date(state.exp_date) <= new Date()){
                    state.exp_date = '';
                }
            }
            
            if(state.exp_date){
                requestObj.exp_date = state.exp_date;
            }else{
                requestObj.exp_date = null;
            }


            if(new Date(state.exp_date) <= new Date()){
                state.status = 'disabled';
                requestObj.status = 'disabled'
            }
            
            
            // Check to see if user account already exists
            let requestOptions = {
                method: 'GET',
                credentials: 'include',
            };
    
            response = await fetch('https://picform.lahlouhonline.com/api/users', requestOptions)

            data = await response.json()

            let addUser = true;
            if(data){
                for(let a=0;a<data.length;a++){
                    if(data[a].username === state.username){
                        addUser = false;
                        refUsernameError.current.innerHTML = 'Email already exists in our system.';
                        refUsername.current.classList.add('error-outline');
                        refUsernameError.current.style.display='block';  
                    }
                }
            }

            // Create New User
            if(addUser){
                requestOptions = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestObj)
                };
                
                response = await fetch('https://picform.lahlouhonline.com/api/users', requestOptions)

                if(response.status === 403){
                    console.log('you are logged out')
                    infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
                }else{
                    data = await response.json()
                    setTimeout(() => {
                        infoModal('Added', 'User has been successfully added', true);
                    }, 150);
                    setTimeout(() => {
                        navigate(-1);
                    }, 2100);
                    //data.username && navigate('/thankyou', { state: { type: 'user' } });
                }
            }
        }
    }
    // End of handleSubmit
    
    // Pull in MySQL Data - Only once on page load
    useEffect(() => {
        // Verify JWT Token, and then pull data
        const verifyUser = async () => {
            let requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
            };

            // Get Portals
            let response = await fetch('https://picform.lahlouhonline.com/api/clients', requestOptions);
            let data = await response.json();
            if(data && data.length > 0){
                await setPortals(data);

                // Add options to Portal select dropdown
                for(let a=0;a<data.length;a++){
                    let option = document.createElement("option");
                    option.text = data[a].name;
                    option.value = data[a].customer_number;
                    refPortal.current.add(option);
                }
            }

            requestOptions = {
                method: 'POST',
                credentials: 'include',
            };
  
          response = await fetch('https://picform.lahlouhonline.com/api/verify', requestOptions);
          data = await response.json()
          if(data.username){
            setUser(data)
          }else{
            await setUser(null)
          }
        }
  
        verifyUser();

        refUsername.current.focus();
        
    }, [])

    function nullToString(obj){
        for(let i=0;i<Object.values(obj).length;i++){
            if(Object.values(obj)[i] === null){
            obj[Object.keys(obj)[i]] = ''
            }
        }
        return obj;
    }

    // Informational Modal
    function infoModal(heading, content, autoclose = false){
        modalHeading.current.innerHTML = heading;
        modalContent.current.innerHTML = content;
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        overlay.current.className = 'show';
        popup.current.className = 'show';
        if(autoclose){
            modalClose.current.style.display = 'none';
            setTimeout(() => {
                document.body.style.overflow = "auto";
                document.body.style.marginRight = '0';
                if(overlay.current) overlay.current.className = '';
                if(popup.current) popup.current.className = '';
            }, 2000)
        }else{
            modalClose.current.style.display = 'block';
        }
    }

    // Modal functionality for label headings
    function showModal(e){
        e.preventDefault();
        modalHeading.current.innerHTML = e.target.innerHTML;
        modalContent.current.innerHTML = e.target.title;
        
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';

        overlay.current.className = 'show';
        popup.current.className = 'show';
    }

    function closePopup(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(overlay.current) overlay.current.className = '';
        if(popup.current) popup.current.className = '';
    }

    // Close popup by clicking anywhere outside of it
    window.onclick = function(event) {
        if (event.target.id === 'overlay') {
            document.body.style.overflow = "auto";
            document.body.style.marginRight = '0';
            if(overlay.current) overlay.current.className = '';
            if(popup.current) popup.current.className = '';
        }
    }


    // Back to top scroll function
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "block";
            }
        } else {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "none";
            }
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    async function deleteItem(e){
        let id = e.target.parentNode.getAttribute('data-id')
        let newValue = state.customer_number.split(',').filter(item => item != id).join(',');

        setState(prevState => ({
            ...prevState,
            ['customer_number']: newValue
        }));    
    }

    if(state === null || (user && user.role != 'admin')){
        return (
          <div className="main">
            <div className="main-content">
                
                {state === null
                ?
                <>
                <h1 className="mt10">User Details</h1>
                <div className="mt15 center"><h3 style={{fontWeight:'400'}}>No user to display.</h3></div>
                </>
                :
                <div className="mt15 center"><h3 style={{fontWeight:'400'}}>Unauthorized Access</h3></div>
                }
            </div>
          </div>
        )
    } 
    else{
        return (
        <>
        <div className="main">
            <div className="main-content">
                <h1 className="mt10">Add User</h1>
        
                <div className="request-header slate-bg mt20" style={{height:'62px'}}>
                    {/* Back to All Users Arrow */}
                    <div className="back-to-users">
                        <Link to="/users" title="Back to all users" className="left-angle" />
                    </div>
                </div>
                
                <div className="form-bottom">
                    
                    {/* Username */}
                    <div className="element">
                        <label className="required" title="This is the email address used to login" onClick={event => showModal(event)}>Email</label>
                        <input
                            type="text"
                            ref={refUsername}
                            name="username"
                            value={state.username}
                            autoComplete="new-username"
                            onChange={handleChange}
                        />
                        <div ref={refUsernameError} className="errorMessage"></div>
                    </div>

                    {/* Password */}
                    <div className="element">
                        <label className="required" title="This is the password used to login" onClick={event => showModal(event)}>Password</label>
                        <input
                            type="password"
                            ref={refPassword}
                            name="password"
                            value={state.password}
                            autoComplete="new-password"
                            onChange={handleChange}
                        />
                        <div ref={refPasswordError} className="errorMessage"></div>
                    </div>
                    
                    {/* First Name */}
                    <div className="element">
                        <label className="required">First Name</label>
                        <input
                            type="text"
                            ref={refFirstName}
                            name="fname"
                            value={state.fname}
                            onChange={handleChange}
                        />
                        <div ref={refFirstNameError} className="errorMessage"></div>
                    </div>

                    {/* Last Name */}
                    <div className="element">
                        <label className="required">Last Name</label>
                        <input
                            type="text"
                            ref={refLastName}
                            name="lname"
                            value={state.lname}
                            onChange={handleChange}
                        />
                        <div ref={refLastNameError} className="errorMessage"></div>
                    </div>

                    {/* Status */}
                    <div className="element">
                        <label className="required">Status</label>
                        <select 
                            name="status"
                            ref={refStatus}
                            value={state.status} 
                            onChange={handleChange}
                        >
                            <option>Select an option...</option>
                            <option value="active">Active</option>
                            <option value="disabled">Disabled</option>
                        </select>
                        <div ref={refStatusError} className="errorMessage"></div>
                    </div>

                    {/* Role */}
                    <div className="element">
                        <label className="required">Role</label>
                        <select 
                            name="role"
                            ref={refRole}
                            value={state.role} 
                            onChange={handleChange}
                        >
                            <option>Select an option...</option>
                            <option value="admin">Admin</option>
                            <option value="sales">Sales</option>
                            <option value="external">External</option>
                        </select>
                        <div ref={refRoleError} className="errorMessage"></div>
                    </div>

                    {/* Portal Selection */}
                    <div className="element">
                        <label 
                            className={state.role === 'sales' || state.role === 'external' ? "required" : "hide"}
                        >Portal</label>
                        
                        {state.customer_number && portals && state.customer_number.split(',').map(o => (
                        <div 
                          key={o}
                          data-id={o}
                          className={state.role === 'sales' || state.role === 'external' ? "portal-list-item" : "hide"}
                        >
                            {portals.filter(portal => portal.customer_number == o)[0].name}
                            <span 
                                className="delete-list-item"
                                title="Remove Portal"
                                onClick={deleteItem}
                            >x</span>
                        </div>
                        ))}
                        
                        <select 
                            name="customer_number"
                            ref={refPortal}
                            value={state.customer_number} 
                            onChange={handleChange}
                            className={state.role === 'sales' || state.role === 'external' ? "" : "hide"}

                        >
                            <option>Select an option...</option>
                        </select>

                        <div ref={refPortalError} className="errorMessage">
                            Please select a portal.
                        </div>
                    </div>

                    {/* Expiration Date */}
                    <div className="element">
                        <label title="This is the date in which the user account will expire." onClick={event => showModal(event)}>Expiration Date</label>
                        <input
                            type="date"
                            name="exp_date"
                            ref={refExpDate}
                            value={state.exp_date}
                            onChange={handleChange}
                            onKeyDown={(e) => e.preventDefault()}
                        />
                        <div ref={refExpDateError} className="errorMessage"></div>
                    </div>
                </div>

                <div ref={refSubmit} className="mt20 center">
                  <button className="primary-button" onClick={handleSubmit} title="Add User">Add</button>
                </div>
            </div>
        </div>

        <div ref={refReturnTop} className="return-top" onClick={topFunction}>
            &#x25B2; Return to top
        </div>

        <div id="overlay" ref={overlay}></div>

        <div id="popup" ref={popup}>
            <h2 id="modal-heading" ref={modalHeading} className="center">Heading</h2>
            <span id="popup-close" ref={modalClose} className="close" onClick={closePopup}>&times;</span>
            <div id="modal-content" ref={modalContent} className="content center">
            Text changed through JS
            </div>
        </div>
        </>
        )
    }
}
