function addBusinessDays(date, add){
    for (let i = 1; i <= add; i++) {
        date.setDate(date.getDate() + 1);
        if (date.getDay() === 6) {
            date.setDate(date.getDate() + 2);
        }
        else if (date.getDay() === 0) {
            date.setDate(date.getDate() + 1);
        }
    }

    return date;
}

// Date format functions
function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}
    
// Backup of Old Format Date Function
// function formatDate(date) {
//     return [
//         padTo2Digits(date.getMonth() + 1),
//         padTo2Digits(date.getDate()),
//         date.getFullYear(),
//     ].join('/');
// }

function formatSimpleDate(date){
    var newDate = new Date(date);
    //newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return [
        padTo2Digits(newDate.getMonth() + 1),
        padTo2Digits(newDate.getDate()),
        newDate.getFullYear(),
    ].join('/');
}

// Old formatDate
//Was using non-UTC functions (converting to local time)
function formatDate(date) {
    var newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return [
        padTo2Digits(newDate.getMonth() + 1),
        padTo2Digits(newDate.getDate()),
        newDate.getFullYear(),
    ].join('/');
}

function formatISODate(date) {
    var newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return [
        newDate.getFullYear(),
        padTo2Digits(newDate.getMonth() + 1),
        padTo2Digits(newDate.getDate())
    ].join('-');
}

// New function utilizing UTC to display
// Inputs are all UTC based
// For effective date
function formatDatePicker(date) {
    return [
        padTo2Digits(date.getUTCMonth() + 1),
        padTo2Digits(date.getUTCDate()),
        date.getUTCFullYear(),
    ].join('/');
}

// Converts MySQL UTC-Based DATETIME into local DATE format
function dbDate(date){
    var newDate = new Date(date);
    //newDate = newDate.toLocaleString().split(',')[0]
    let dateStr =  [
        newDate.getFullYear(),    
        padTo2Digits(newDate.getMonth() + 1),
        padTo2Digits(newDate.getDate())
    ].join('-');
    return dateStr.toLocaleString("en-US").split(',')[0]
}

module.exports = {addBusinessDays, formatSimpleDate, formatDate, formatISODate, formatDatePicker, dbDate }

