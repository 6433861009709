import React, { useState, useRef, useEffect, useContext } from 'react'
import { UserContext } from '../context/UserContext';
import { useNavigate } from "react-router-dom";
import { formatDatePicker } from '../Utils/utils'

export default function AddChange() {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [confirm, setConfirm] = useState(null)
    // Does logged in user have pending requests or not
    const [pending, setPending] = useState(false)
    const [requests, setRequests] = useState(null)

    let companyName = null;

    // DOM References
    const refPartNum = useRef();
    const refPartNumError = useRef();
    const refName = useRef();
    const refNameError = useRef();
    const refDate = useRef();
    const refDateError = useRef();
    const refDateCharge = useRef();
    const refReturnTop = useRef();
    const refNextDay = useRef();
    const refImage = useRef();
    const refImageError = useRef();
    const refImageDetails = useRef();
    const refCustomerNumber = useRef();
    const refCustomerNumberError = useRef();
    const refPortal = useRef();
    const refPortalError = useRef();
    const refErrors = useRef();
    const refPortalList = useRef();
    const refVeraCore = useRef();
    const refVeraCoreError = useRef();
    const refHoldDate = useRef();
    

    //Overlay References
    const overlay = useRef();
    const loadingOverlay = useRef();
    const popup = useRef();
    const modalHeading = useRef();
    const modalContent = useRef();
    const modalClose = useRef();
    const popupConfirm = useRef();
    const popupConfirmHeading = useRef();
    const popupConfirmContent = useRef();
    const popupConfirmClose = useRef();

    const [state, setState] = useState({
        action: 'Image Update', 
        image: 'sample.jpg', 
        partnum: '', 
        name: '', 
        effective_date: '', 
        next_day: '',
        client_notes: '',
        vc_product_id: '',
        customer_number:'',
        hold_date: 'no'
    });

    const handleChange = e => {
        const { name, value } = e.target;
        
        if(name === "effective_date"){
            refDateCharge.current.classList.remove("show");
            // Next Day Logic
            let nextDay = false;
            const todaysDate = new Date();
            todaysDate.setHours(0,0,0,0);
    
            // Grab entered effective date at midnight
            const eDate = new Date(value + ' 00:00:00');
    
            // Add 1 business day to today's date
            const newDate = new Date(addBusinessDays(todaysDate, 1));
            
            // Date is 1 business day in the future
            if(eDate.getTime() === newDate.getTime()){
                refDateCharge.current.classList.add("show");
            }
        }
        
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Confirm Modal
    async function confirmOverlay(){
        let text = '<div class="mt10 center">Would you like to enter another request?</div>';

        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
       
        popupConfirmContent.current.innerHTML = text;
        overlay.current.className = 'show';
        popupConfirm.current.className = 'show';
    }

    // Form Submit & Validation
    async function handleSubmit(){
        let shouldSubmit = true;
        let nextDay = false;

        refName.current.classList.remove('error-outline');
        refNameError.current.style.display = 'none';
        refPartNum.current.classList.remove('error-outline');
        refPartNumError.current.style.display = 'none';
        refDate.current.classList.remove('error-outline');
        refDateError.current.style.display = 'none';
        refImageError.current.style.display = 'none';
        if(user.role === 'admin' || user.role === 'sales'){
            refVeraCore.current.classList.remove('error-outline');
            refVeraCoreError.current.style.display='none';
            refPortal.current.classList.remove('error-outline');
            refPortalError.current.style.display='none';
        }
        if(!refNextDay.current.checked) refDateCharge.current.style.display = 'none';
        refErrors.current.classList.remove('show');
        
        // Image Validation (when attached)
        if(refImage.current.files[0]){
            let type = refImage.current.files[0].type;
            if(type.indexOf('jpeg') === -1 && type.indexOf('png') === -1){
                refImageError.current.innerHTML = 'Please upload an image in JPG or PNG format.'
                refImageError.current.style.display='block';
                shouldSubmit = false;
            }
        }else{
            refImageError.current.innerHTML = 'Please upload the replacement image.'
            refImageError.current.style.display='block';
            shouldSubmit = false;
        }

        // Part Number Validation - Empty String
        if(state.partnum === ''){
            refPartNumError.current.innerHTML = 'Please enter a part number.';
            refPartNum.current.classList.add('error-outline');
            refPartNumError.current.style.display='block';
            shouldSubmit = false;
        }else{
            let dupes = requests.filter(item => item.partnum === refPartNum.current.value && (item.request_status === 'Received' || item.request_status === 'Fix' || item.request_status === 'In Progress'));
            if(dupes.length > 0){
                infoModal("Duplicate", "This part number is already associated with an open request")    
                refPartNumError.current.innerHTML = 'This part number is already associated with an open request';
                refPartNum.current.classList.add('error-outline');
                refPartNumError.current.style.display='block';
                shouldSubmit = false;
            }
        }

        // Part Number Validation - Regex
        // if(state.partnum !== ''){
        //     const regex = /^[0-9A-Za-z\s-]+$/
        //     if(!state.partnum.match(regex)){
        //         refPartNumError.current.innerHTML = 'Only numbers, letters and dashes are allowed in the part number.';
        //         refPartNum.current.classList.add('error-outline');
        //         refPartNumError.current.style.display='block';
        //         shouldSubmit = false;
        //     }
        // }

        if(state.name === ''){
            refName.current.classList.add('error-outline');
            refNameError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.effective_date === '' && user.role !== 'external'){
            refDateError.current.innerHTML = 'Please enter an effective date.';
            refDate.current.classList.add('error-outline');
            refDateError.current.style.display='block';
            shouldSubmit = false;
        }

        if(state.effective_date !== '' && user.role !== 'external'){
            // No Weekends
            let dayNum = new Date(refDate.current.value).getUTCDay();
            if(dayNum === 0 || dayNum === 6){
                refDateError.current.innerHTML = 'Effective date cannot be set to a weekend.';
                refNextDay.current.checked = false;
                refDateCharge.current.style.display='none';
                refDate.current.classList.add('error-outline');
                refDateError.current.style.display='block';
                shouldSubmit = false;
            }
            
            // Grab todays date and set hours to exactly midnight
            const todaysDate = new Date();
            todaysDate.setHours(0,0,0,0);

            // Grab entered effective date at midnight
            const eDate = new Date(state.effective_date + ' 00:00:00');

            // Add 1 business day to today's date
            const newDate = new Date(addBusinessDays(todaysDate, 1));
            
            // Date is today or earlier
            if(eDate.getTime() < newDate.getTime()){
                refDateError.current.innerHTML = 'Please choose a date at least 1 business day in the future.';
                refNextDay.current.checked = false;
                refDateCharge.current.style.display='none';
                refDate.current.classList.add('error-outline');
                refDateError.current.style.display='block';
                shouldSubmit = false;
            }

            // Date is only 1 business day in the future
            // Add next day messaging
            if(eDate.getTime() === newDate.getTime()){
                nextDay = true;
                if(!refNextDay.current.checked){
                    refDate.current.classList.add('error-outline');
                    refDateCharge.current.style.display='flex';
                    shouldSubmit = false;
                }
            }
        }

        // Portal Validation (Only for Admin or Sales)
        if(user.role === 'admin' || user.role === 'sales'){
            // VeraCore Product ID Validation
            if(state.vc_product_id === ''){
                refVeraCoreError.current.innerHTML = 'Please enter the VeraCore Product ID.';
                refVeraCore.current.classList.add('error-outline');
                refVeraCoreError.current.style.display='block';
                shouldSubmit = false;
            }

            // VeraCore Product ID RegEx
            if(state.vc_product_id !== ''){
                const regex = /^[0-9A-Za-z-]+$/
                if(!state.vc_product_id.match(regex)){
                    refVeraCoreError.current.innerHTML = 'Only numbers, letters and dashes are allowed in the part number.';
                    refVeraCore.current.classList.add('error-outline');
                    refVeraCoreError.current.style.display='block';
                    shouldSubmit = false;
                }
            }

            // Portal Selection Validation
            if(refPortal.current.value.indexOf('Select an') != -1){
                refPortal.current.classList.add('error-outline');
                refPortalError.current.style.display='block';
                shouldSubmit = false;
            }else{
                // Grab Company Name from Clients Table
                let requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                let response = await fetch('https://picform.lahlouhonline.com/api/client/' + state.customer_number, requestOptions)
                let data = await response.json();
                if(!data.error){
                    companyName = data.name;
                }
            }
        // Add Company Name for Teams Message when role = external
        }else{
            let requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' }
            };

            let response = await fetch('https://picform.lahlouhonline.com/api/client/' + user.customer_number, requestOptions)
            let data = await response.json();
            if(!data.error){
                companyName = data.name;
            }else{
                companyName = 'Unknown';
            }
        }

        // Group Request Code
        if(shouldSubmit && confirm === null){
            confirmOverlay();
            return;
        }

        // Passed all form validation, submit to Teams
        // Group Request Version
        if(shouldSubmit && confirm != null){
            //if(shouldSubmit){

            // Show overlay
            showLoading();
            
            // Upload Image
            let imageFilename = null
            if(refImage.current.files[0]){

                //let formData = new FormData(refForm.current)
                let formData = new FormData()
                formData.append('image', refImage.current.files[0])
                
                let requestOptions = {
                    method: 'POST',
                    credentials: 'include',
                    redirect: 'manual',
                    body: formData,
                };
                
                let response = await fetch('https://picform.lahlouhonline.com/api/image', requestOptions)
                
                if(response.status === 403){
                    hideLoading();
                    infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
                    return false;
                }else{
                    let data = await response.json()
                    if(data.filename){
                        imageFilename = data.filename
                    }
                }
            }

            // Create request object with the default fields
            let requestObj={
                action: state.action,
                request_status: "Received",
                submitter_email: user.username,
                group_request: ""
            }

            if(user.role === 'external'){
                requestObj.request_status = 'In Review';
            }

            if(imageFilename !== null) requestObj.image = imageFilename;
            if(state.partnum) requestObj.partnum = state.partnum
            if(state.name) requestObj.name = state.name.trim();
            if(state.effective_date) requestObj.effective_date = state.effective_date
            if(refNextDay.current){
                if(refNextDay.current.checked && nextDay) requestObj.next_day = "yes";
            }
            if(refHoldDate.current){if(refHoldDate.current.checked) requestObj.hold_date = "yes";}
            if(state.client_notes) requestObj.client_notes = state.client_notes
            if(state.vc_product_id) requestObj.vc_product_id = state.vc_product_id
            if(state.customer_number){
                requestObj.customer_number = state.customer_number
            }else{
                requestObj.customer_number = user.customer_number
            }

            // Group Request Code
            if(confirm === true || pending) requestObj.request_status = 'Pending'

            // // If other pending requests
            let requestOptions = {
                method: 'GET',
                credentials: 'include',
            };
  
            // Submit to PICDB
            requestOptions = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestObj)
            };
            
            let response = await fetch('https://picform.lahlouhonline.com/api/requests', requestOptions)
                        
            if(response.status === 403){
                hideLoading();
                infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
            }else{
                let data = await response.json()

                if(data.name){
                    // Teams Message
                    const teamsObj =
                    {
                        "@context": "https://schema.org/extensions",
                        "@type": "MessageCard",
                        "themeColor": "0076D7",
                        "summary": "Summary",
                        "sections": [
                            {
                                "activityTitle": "Portal Item Change Request",
                                "facts": [
                                    {
                                        "name": "Type:",
                                        "value": "New request"
                                    },
                                    {
                                        "name": "Action:",
                                        "value": state.action
                                    },
                                    {
                                        "name": "Company:",
                                        "value": companyName
                                    },
                                    {
                                        "name": "Submitted By:",
                                        "value": requestObj.submitter_email
                                    },
                                    {
                                        "name": "Role:",
                                        "value": user.role
                                    },
                                    {
                                        "name": "Effective Date:",
                                        "value": formatDatePicker(new Date(state.effective_date))
                                    }
                                ],
                                "text": "https://picform.lahlouhonline.com/request?id=" + data.id
                            }
                        ]
                    }

                    // Send to Teams
                    requestOptions = {
                        method: 'POST',
                        credentials: 'include',
                        //mode: 'no-cors',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(teamsObj)
                    };
                
                    // Send Teams Message
                    // Not for external clients / test portal / pending
                    if(user.role !== 'external' && companyName.indexOf('Test Portal') === -1 && requestObj.request_status !== "Pending"){
                        fetch('https://picform.lahlouhonline.com/api/teams/' + data.id, requestOptions)
                    }

                    hideLoading();
                }
            }
        }

        // Group Request Code
        if(shouldSubmit){
            if(confirm){
                navigate('/')
                return;
            } 
            if(pending){
                infoModal("Pending Requests", "You have pending requests. Redirecting to the 'Requests' page.")    
                setTimeout(() => {
                    closePopup();
                    navigate('/requests')
                    return;
                }, 2850);
            }else{
                closePopup();
                navigate('/thankyou', { state: { type: 'request' } });
                return;
            }
        }else{
            if(refErrors.current) refErrors.current.classList.add('show');
        }
    }
    // End of handleSubmit
    
    // Informational Modal
    function infoModal(heading, content, autoclose = false){
        modalHeading.current.innerHTML = heading;
        modalContent.current.innerHTML = content;
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        overlay.current.className = 'show';
        popup.current.className = 'show';
        if(autoclose){
            modalClose.current.style.display = 'none';
            setTimeout(() => {
                document.body.style.overflow = "auto";
                document.body.style.marginRight = '0';
                overlay.current.className = '';
                popup.current.className = '';
            }, 2000)
        }else{
            modalClose.current.style.display = 'block';
        }
    }
    
    // Modal functionality for label headings
    function showModal(e){
        e.preventDefault();
        modalHeading.current.innerHTML = e.target.innerHTML;
        modalContent.current.innerHTML = e.target.title;
        
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';

        overlay.current.className = 'show';
        popup.current.className = 'show';
    }

    function showLoading(){
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        if(loadingOverlay.current) loadingOverlay.current.className = 'show';
    }

    function hideLoading(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(loadingOverlay.current) loadingOverlay.current.className = '';
    }

    function closePopup(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(overlay.current) overlay.current.className = '';
        if(popup.current) popup.current.className = '';
        if(popupConfirm.current) popupConfirm.current.className = '';
    }
    
    // Close popup by clicking anywhere outside of it
    window.onclick = function(event) {
        if (event.target.id === 'overlay') {
            document.body.style.overflow = "auto";
            document.body.style.marginRight = '0';
            if(overlay.current) overlay.current.className = '';
            if(popup.current) popup.current.className = '';
            if(popupConfirm.current) popupConfirm.current.className = '';
        }
    }

    // Back to top scroll function
    window.onscroll = function() {scrollFunction()}

    function scrollFunction() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "block";
            }
        } else {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "none";
            }
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    function addBusinessDays(date, add){
        for (let i = 1; i <= add; i++) {
            date.setDate(date.getDate() + 1);
            if (date.getDay() === 6) {
                date.setDate(date.getDate() + 2);
            }
            else if (date.getDay() === 0) {
                date.setDate(date.getDate() + 1);
            }
        }

        return date;
    }

    function updateImageDetails(){
        if(refImage.current.files[0]){
            let str = refImage.current.files.length;
            str += ' image';
            if(refImage.current.files.length > 1) str += 's';
            refImageDetails.current.innerHTML = str + ' attached';
            
            
            /* Get Image Details */
            // let image, w, h;
            // for(let a=0;a<refImage.current.files.length;a++){
            //     image = new Image();
            //     image.src = window.URL.createObjectURL( refImage.current.files[a] );
            //     image.onload = function() 
            //     {
            //         var width = this.naturalWidth,
            //         height = this.naturalHeight;
                        
            //     console.log ("Image Width: " + width);
            //     console.log ("Image Height: " +height);
            //     };
            // }
        }
    }

    function imageLoaded(){
        console.log('image loaded')
        let image, w, h;
        for(let a=0;a<refImage.current.files.length;a++){
            image = new Image();
            image.src = refImage.current.files[a];
            console.log(image.src)
            w = image.naturalWidth;
            h = image.naturalHeight;
            console.log(a + ' image details - w: ' + w + ', h: ' + h)
        }
    }

    // useEffect to be ran on page load only
    useEffect(() => {
        // Verify JWT Token
        const verifyUser = async () => {
            let requestOptions = {
                method: 'POST',
                credentials: 'include',
            };
      
            let response = await fetch('https://picform.lahlouhonline.com/api/verify', requestOptions);
            let data = await response.json()
            if(data.username){
                let todaysDate = new Date();
                todaysDate.setHours(0,0,0,0);
                const newDate = new Date(addBusinessDays(todaysDate, 1)).toISOString().split('T')[0];
                // Datepicker - 1 business day in the future, gray out past days
                refDate.current.setAttribute('min', newDate);
                //refDate.current.value = newDate;

                let requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                };

                let response = await fetch('https://picform.lahlouhonline.com/api/clients', requestOptions);
                let data = await response.json()
                
                // Pull in Portal List
                if(data){
                    // Build Portal Select Dropdown
                    let option;
                    for(let a=0;a<data.length;a++){
                        if(data[a].active === 1){
                            option = document.createElement("option");
                            option.text = data[a].name;
                            option.value = data[a].customer_number;
                            refPortal.current.add(option);
                        }
                    }

                    // Build 'My Portals' list
                    if(refPortalList.current){
                        let li, anchor;
                        let split = user.customer_number.split(',');
                        for(let a=0;a<split.length;a++){
                            for(let b=0;b<data.length;b++){
                                if(split[a] == data[b].customer_number && data[b].active === 1){
                                    li = document.createElement('li');
                                    li.value = data[b].customer_number;
                                    anchor = document.createElement('a');
                                    anchor.setAttribute('title','Set to ' + data[b].name);
                                    anchor.onclick = setDropdown;
                                    anchor.appendChild(document.createTextNode(data[b].name))
                                    li.appendChild(anchor);
                                    refPortalList.current.appendChild(li);
                                    break;
                                }
                            }
                        }
                    }
                }

                // Check to see if logged in user has any pending requests
                response = await fetch('https://picform.lahlouhonline.com/api/requests', requestOptions)
                data = await response.json();
                if(data){
                    setRequests(data)
                    // Filter to only pending requests for current user
                    let myPending = data.filter(item => item.request_status === 'Pending' && item.submitter_email === user.username);
                    if(myPending && myPending.length >0){
                        setPending(true)
                    }
                }
            }else{
                await setUser(null)
            }
        }
        verifyUser();
    }, [])

    useEffect(() => {
        if(confirm === true || confirm === false) handleSubmit()
    }, [confirm]);

    function setDropdown(e){
        let value = e.target.parentNode.getAttribute('value')
        const items = refPortal.current.options;

        for(let a=0;a<items.length;a++){
            if(items[a].value === value){
                items[a].selected = true;
                setState(prevState => ({
                    ...prevState,
                    ['customer_number']: value
                }));
                break;
            }
        }
    }

    if(!user) return null

    return (
    <>
    <div className="main">
        <div className="main-content">
            <h1 className="mt10">Update Image</h1>

            <div className="mt20 center">
                Hover or click on a heading to see additional information when available.
            </div>

            <div className="mt10 center">
                Required information is marked with an asterisk (<span className="required"></span>)
            </div>

            <div className="form-header slate-bg mt20"></div>

            <div className={user.role === 'admin' || user.role === 'sales' ? "form-top" : "form-bottom"}>
                {/* Product Image */}
                <div className="element">
                    <label title="Any images that will be displayed on the portal. Standard default is to display the front page or front panel of the final image. For specific ordering, please name your files accordingly. No PDF format." onClick={event => showModal(event)} className="required">Product Images (Up to 5)</label>
                    
                    <div className="image-wrapper small-width">
                        <div 
                            className="file-button"
                            style={{width:'136px'}}
                            onClick={() => refImage.current.click()}
                            title="Attach file(s)"
                        ><img src="./assets/images/upload2.png" />&nbsp;Choose Files</div>
                        <span ref={refImageDetails}></span>
                        <input 
                            name="image"
                            ref={refImage}
                            className="mt5 hide"
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            multiple={true}
                            onChange={updateImageDetails}
                            onLoad={imageLoaded}
                        />
                    </div>
                    
                    <div ref={refImageError} className="errorMessage"></div>
                </div>

                {/* Part Number */}
                <div className="element">
                    <label className="required" title="Enter the client part number, which usually matches the Veracore Product ID." onClick={event => showModal(event)}>Client Part No. (SKU)</label>
                    <input 
                        name="partnum"
                        ref={refPartNum}
                        type="text"
                        value={state.partnum}
                        onChange={handleChange}
                    />

                    <div ref={refPartNumError} className="errorMessage"></div>
                </div>
                
                {/* Product Title */}
                <div className="element">
                    <label className="required" title="Enter the product title as it appears on the portal." onClick={event => showModal(event)}>Product Title</label>
                    <input 
                        name="name"
                        ref={refName}
                        type="text"
                        min="2022-11-06"
                        value={state.name}
                        onChange={handleChange}
                    />
                    <div ref={refNameError} className="errorMessage">
                        Please enter a product title.
                    </div>
                </div>

                {/* Effective Date */}
                <div className="element">
                    <label className={user.role !== "external" ? "required" : undefined} title="Change could go live sooner than this date depending on the teams workload. Check the 'Hold until effective date' checkbox if needed." onClick={event => showModal(event)}>Effective Date</label>
                    <input 
                        name="effective_date"
                        ref={refDate}
                        type="date"
                        value={state.effective_date}
                        onChange={handleChange}
                        onKeyDown={(e) => e.preventDefault()}
                    />

                    <div ref={refDateError} className="errorMessage"></div>

                    <div ref={refDateCharge} className="radio-check-wrapper errorMessage">
                      <label className="checkbox-container no-highlight mt5">
                            <input 
                                ref={refNextDay}
                                type="checkbox"
                                name="next_day"
                                id="next_day"
                                value={state.next_day}
                                onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                            Next day service may incur an additional charge.
                        </label>
                    </div>
                </div>

                {/* Hold Date */}
                <div className="element">
                    <div className="radio-check-wrapper padLeft8">
                      <label className="checkbox-container no-highlight">
                            <input 
                                ref={refHoldDate}
                                type="checkbox"
                                name="hold-date-check"
                                id="hold-date"
                                //value="yes"
                                //value={state.hold_date}
                                //checked={state.hold_date === 'yes'}
                                onChange={handleChange}
                            />
                            <span className="checkmark"></span>
                            Hold until effective date
                        </label>
                    </div>
                </div>

                {/* Product Notes */}
                <div className="element">
                    <label title="Enter any notes that will aid in the updating of this product image." onClick={event => showModal(event)}>
                        Additional Product Notes
                    </label>    
                    <div className="textarea-wrapper">
                        <textarea 
                            name="client_notes"
                            value={state.client_notes}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>


            {(user.role === 'admin' || user.role === 'sales') &&
            <div className="internal-content">
                <div className="form-divider yellow-bg center">
                    Lahlouh Internal
                </div>

                {/* <h2 className="center">Lahlouh Details</h2> */}
                
                <div className="form-bottom">
                    {/* VeraCore Product ID */}
                    <div className="element">
                        <label className="required" title="This is the product ID that is assigned by Lahlouh. Ideally, it is the same as the Client Part No. (SKU) and should be included on the physical inventory item. It is included on all carton labels." onClick={event => showModal(event)}>
                            VeraCore Product ID #
                        </label> 
                        <input
                        type="text"
                        name="vc_product_id"
                        ref={refVeraCore}
                        value={state.vc_product_id}
                        onChange={handleChange}
                        disabled={state.request_status === 'Completed' || state.request_status === 'In Progress' ? true : false}
                        />

                        <div ref={refVeraCoreError} className="errorMessage">
                            Please enter the VeraCore Product ID.
                        </div>
                    </div>

                    {/* Portal Selection */}
                    <div className="element">
                        <label className="required">Portal Selection</label>

                        {user.customer_number.split(',')[0] !== '1' && user.role === 'sales' &&
                        <div className="portal-list-wrapper padLeft8">
                            <strong>My Portals</strong>
                            <ul ref={refPortalList} className="portal-list mt5">
                            </ul>
                        </div>
                        }

                        <select 
                            name="customer_number"
                            ref={refPortal}
                            value={state.customer_number} 
                            onChange={handleChange}
                        >
                            <option>Select an option...</option>
                        </select>

                        <div ref={refPortalError} className="errorMessage">
                            Please select a portal.
                        </div>
                    </div>
                </div>
            </div>
            }

            <div ref={refErrors} className="small-width errorMaster center">
                There are items that require your attention above
            </div>
            
            <div className="submit-save-wrapper mt20 center">
                <button className="primary-button" title="Submit" onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    </div>

    <div ref={refReturnTop} className="return-top" onClick={topFunction}>
      &#x25B2; Return to top
    </div>

    <div id="overlay" ref={overlay}></div>

    <div id="loading-overlay" ref={loadingOverlay}>
        <div className="deadCenter">
            <div className="lds-spinner">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    </div>

    <div id="popup" ref={popup}>
        <h2 id="modal-heading" className="center" ref={modalHeading}></h2>
        <span id="popup-close" ref={modalClose} className="close" onClick={closePopup}>&times;</span>
        <div id="modal-content" ref={modalContent} className="content center"></div>
    </div>

    <div id="popup-confirm" ref={popupConfirm}>
        <h2 id="modal-heading" ref={popupConfirmHeading} style={{textAlign:'center'}}>Anything Else?</h2>
        <span className="close" ref={popupConfirmClose} onClick={() => {closePopup();setConfirm(null)}}>&times;</span>
        <div id="modal-content" ref={popupConfirmContent} className="content center">
          Text changed through JS
        </div>
        <div className="mt10 center">
          <button className="primary-button smaller-button" onClick={(e) => {closePopup();overlay.current.className = 'show';setConfirm(true);}} title="Yes">Yes</button>
          <button className="secondary-button smaller-button" onClick={() => {closePopup();overlay.current.className = 'show';setConfirm(false);}} title="No">No</button>
        </div>
    </div>
    </>
  )
}
