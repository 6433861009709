import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function UsersTable(props) {
    const { users } = props;
    let sortKey = sessionStorage.getItem('userSortKey');
    let sortDir = sessionStorage.getItem('userSortDir');
    const [sortConfig, setSortConfig] = useState({key: sortKey !== '' && sortKey !== null ? sortKey : 'username', direction: sortDir !== '' && sortDir !== null ? sortDir : 'ascending'});
    const navigate = useNavigate();

    let sortedUsers = [...users];

    sortedUsers.sort((a, b) => {
        if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const requestSort = key => {
        let direction = 'ascending';
        
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
        sessionStorage.setItem('userSortKey', key);
        sessionStorage.setItem('userSortDir', direction);
    }

    function setArrow(str){
        if(sortConfig.key === str){
            if(sortConfig.direction === 'ascending'){
                return 'arrow-up'
            }else{
                return 'arrow-down';
            }
        }else{
            return null
        }
    }

    return (
        <table className="users-table mt15" cellSpacing="0" cellPadding="0">
        <thead>
            <tr>
                <th onClick={() => requestSort('username')} title="Sort by Username">
                    Username <span className={setArrow('username')}></span>
                </th>
                <th onClick={() => requestSort('fname')} title="Sort by First Name">
                    First Name <span className={setArrow('fname')}></span>
                </th>
                <th onClick={() => requestSort('lname')} title="Sort by Last Name">
                    Last Name <span className={setArrow('lname')}></span>
                </th>
                <th onClick={() => requestSort('role')} title="Sort by Role">
                    Role <span className={setArrow('role')}></span>
                </th>
                <th onClick={() => requestSort('status')} title="Sort by Status">
                    Status <span className={setArrow('status')}></span>
                </th>
                <th onClick={() => requestSort('company')} title="Sort by Portal">
                    Portal <span className={setArrow('company')}></span>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        {sortedUsers.map(user => {
            return (
            <tr key={user.id} title={"Click to view/edit " + user.username} onClick={() => navigate('/user?id=' + user.id)} style={{color: user.status === 'disabled' && '#a79b72'}}>
                <td data-label="Username">{user.username}</td>
                <td data-label="First Name">{user.fname}</td>
                <td data-label="Last Name">{user.lname}</td>
                <td data-label="Role">{user.role}</td>
                <td data-label="Status">{user.status}</td>
                <td data-label="Portal">
                    {user.customer_number.split(',').length === 1 ? user.role === 'admin' ? "N/A" : user.company : user.customer_number.split(',').length + ' portals'}</td>
                <td>
                    <div className="edit-icon" title="Edit request" onClick={() => navigate('/user?id=' + user.id)}></div>
                </td>
            </tr>
            )
        })}
        
        </tbody>
        </table>
    )
}
