import React, { useState } from 'react'
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom'

export default function ClientsTable(props) {
    const { clients } = props;
    let sortKey = sessionStorage.getItem('portalSortKey');
    let sortDir = sessionStorage.getItem('portalSortDir');
    const [sortConfig, setSortConfig] = useState({key: sortKey !== '' && sortKey !== null ? sortKey : 'name', direction: sortDir !== '' && sortDir !== null ? sortDir : 'ascending'});
    const navigate = useNavigate();
    
    let sortedClients = [...clients];

    sortedClients.sort((a, b) => {
        if(sortConfig.key === 'customer_number' || sortConfig.key === 'active'){
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
        }else{
            if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
        }
        return 0;
    });

    const requestSort = key => {
        let direction = 'ascending';

        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
        sessionStorage.setItem('portalSortKey', key);
        sessionStorage.setItem('portalSortDir', direction);

    }

    function setArrow(str){
        if(sortConfig.key === str){
            if(sortConfig.direction === 'ascending'){
                return 'arrow-up'
            }else{
                return 'arrow-down';
            }
        }else{
            return null
        }
    }

    return (
        <table className="clients-table mt15" cellSpacing="0" cellPadding="0">
        <thead>
            <tr>
                <th onClick={() => requestSort('customer_number')} title="Sort by Customer #">
                    Customer # <span className={setArrow('customer_number')}></span>
                </th>
                <th onClick={() => requestSort('name')} title="Sort by Portal Name">
                    Portal Name <span className={setArrow('name')}></span>
                </th>
                <th onClick={() => requestSort('epic')} title="Sort by JIRA EPIC">
                    JIRA EPIC <span className={setArrow('epic')}></span>
                </th>
                <th onClick={() => requestSort('platform')} title="Sort by Platform">
                    Platform <span className={setArrow('platform')}></span>
                </th>
                <th onClick={() => requestSort('active')} title="Sort by Status">
                    Status <span className={setArrow('active')}></span>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
        {sortedClients.map(client => {
            return (
            <tr key={client.customer_number} title={"Click to view/edit " + client.customer_number} onClick={() => navigate('/portal?id=' + client.customer_number)} >
                <td data-label="Customer #">{client.customer_number}</td>
                <td data-label="Portal">{client.name}</td>
                <td data-label="EPIC">{client.epic}</td>
                <td data-label="Platform">{client.platform}</td>
                <td data-label="Status">{client.active === 1 ? 'Active' : 'Inactive'}</td>

                <td>
                    <div className="edit-icon" title="Edit request" onClick={() => navigate('/portal?id=' + client.id)}></div>
                </td>
            </tr>
            )
        })}
        
        </tbody>
        </table>
  )
}
