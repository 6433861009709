import React, { useState, useContext, useEffect, useRef } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { formatDate } from '../Utils/utils'

export default function AddPortal() {
    const [state, setState] = useState({
        customer_number: '',
        name: '',
        epic: '',
        platform: '',
        active: ''
    })
    
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    // DOM References
    const refCustomerNumber = useRef();
    const refName = useRef();
    const refEpic = useRef();
    const refReturnTop = useRef();
    const refSubmit = useRef();


    const refCustomerNumberError = useRef();
    const refNameError = useRef();
    const refEpicError = useRef();
    const refPlatform = useRef();
    const refPlatformError = useRef();
    const refStatus = useRef();
    const refStatusError = useRef();

    // Heading Modal References
    const overlay = useRef();
    const popup = useRef();
    const modalHeading = useRef();
    const modalContent = useRef();
    const modalClose = useRef();


    // Handler for all input and textarea elements
    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            //[name]: value ? value : null
            [name]: value
        }));
    };

    async function handleSubmit(){
        let shouldSubmit = true;

        // Get list of portals for data validation
        let requestOptions = {
            method: 'GET',
            credentials: 'include',
        };

        let response = await fetch('https://picform.lahlouhonline.com/api/clients', requestOptions)

        let data = await response.json()

        refCustomerNumber.current.classList.remove('error-outline');
        refCustomerNumberError.current.style.display='none';
        refName.current.classList.remove('error-outline');
        refNameError.current.style.display='none';
        refEpic.current.classList.remove('error-outline');
        refEpicError.current.style.display='none';
        refPlatform.current.classList.remove('error-outline');
        refPlatformError.current.style.display='none';
        refStatus.current.classList.remove('error-outline');
        refStatusError.current.style.display='none';

        // Portal Name Validation
        if(state.name === ''){
            refNameError.current.innerHTML = 'Please enter a portal name.';
            refName.current.classList.add('error-outline');
            refNameError.current.style.display='block';
            shouldSubmit = false;
        }else{
            if(data){
                for(let a=0;a<data.length;a++){
                    if(data[a].name === state.name){
                        refNameError.current.innerHTML = 'Portal name already exists in our system.';
                        refName.current.classList.add('error-outline');
                        refNameError.current.style.display='block';  
                        shouldSubmit = false;
                        break;
                    }
                }
            }
        }

        // EPIC Validation
        if(state.epic === ''){
            refEpicError.current.innerHTML = 'Please enter an associated JIRA EPIC.';
            refEpic.current.classList.add('error-outline');
            refEpicError.current.style.display='block';
            shouldSubmit = false;
        }else{
            if(data){
                for(let a=0;a<data.length;a++){
                    if(data[a].epic === state.epic){
                        refEpicError.current.innerHTML = 'JIRA EPIC already exists in our system.';
                        refEpic.current.classList.add('error-outline');
                        refEpicError.current.style.display='block';  
                        shouldSubmit = false;
                        break;
                    }
                }
            }
        }

        // Platform Validation
        if(state.platform === '' || state.platform.indexOf('Select') !== -1){
            refPlatform.current.classList.add('error-outline');
            refPlatformError.current.style.display='block';
            shouldSubmit = false;
        }

        // Status Validation
        if(state.active === '' || state.active.indexOf('Select') !== -1){
            refStatus.current.classList.add('error-outline');
            refStatusError.current.style.display='block';
            shouldSubmit = false;
        }
 
        // Customer Number Validation
        if(!Number.isInteger(parseInt(state.customer_number))){
            refCustomerNumberError.current.innerHTML = 'Customer number must be numeric.';
            refCustomerNumber.current.classList.add('error-outline');
            refCustomerNumberError.current.style.display='block';
            shouldSubmit = false;
        }
        else if(state.customer_number === ''){
            refCustomerNumberError.current.innerHTML = 'Please enter a customer number.';
            refCustomerNumber.current.classList.add('error-outline');
            refCustomerNumberError.current.style.display='block';
            shouldSubmit = false;
        }else{
            if(data){
                for(let a=0;a<data.length;a++){
                    if(data[a].customer_number == state.customer_number){
                        refCustomerNumberError.current.innerHTML = 'Customer number already exists in our system.';
                        refCustomerNumber.current.classList.add('error-outline');
                        refCustomerNumberError.current.style.display='block';  
                        shouldSubmit = false;
                        break;
                    }
                }
            }
        }

        if(shouldSubmit){
            let requestObj = {
                customer_number: state.customer_number,
                name: state.name,
                epic: state.epic,
                platform: state.platform,
                active: state.active
            }
            
            requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestObj)
            };
            
            response = await fetch('https://picform.lahlouhonline.com/api/clients', requestOptions)

            if(response.status === 403){
                console.log('you are logged out')
                infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
            }else{
                data = await response.json()
                //data.name && navigate('/thankyou', { state: { type: 'client' } });
                setTimeout(() => {
                    infoModal('Added', 'Portal has been successfully added', true);
                }, 150);
                setTimeout(() => {
                    navigate(-1);
                }, 2100);
            }
        }
    }
    
    // Pull in MySQL Data - Only once on page load
    useEffect(() => {
        // Verify JWT Token, and then pull data
        const verifyUser = async () => {
          const requestOptions = {
              method: 'POST',
              credentials: 'include',
          };
  
          let response = await fetch('https://picform.lahlouhonline.com/api/verify', requestOptions);
          let data = await response.json()
          if(data.username){
            setUser(data)
          }else{
            await setUser(null)
          }
        }
  
        verifyUser();

        refCustomerNumber.current.focus();
        
    }, [])

    function nullToString(obj){
        for(let i=0;i<Object.values(obj).length;i++){
            if(Object.values(obj)[i] === null){
            obj[Object.keys(obj)[i]] = ''
            }
        }
        return obj;
    }

    // Informational Modal
    function infoModal(heading, content, autoclose = false){
        modalHeading.current.innerHTML = heading;
        modalContent.current.innerHTML = content;
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        overlay.current.className = 'show';
        popup.current.className = 'show';
        if(autoclose){
            modalClose.current.style.display = 'none';
            setTimeout(() => {
                document.body.style.overflow = "auto";
                document.body.style.marginRight = '0';
                if(overlay.current) overlay.current.className = '';
                if(popup.current) popup.current.className = '';
            }, 2000)
        }else{
            modalClose.current.style.display = 'block';
        }
    }

    // Modal functionality for label headings
    function showModal(e){
        e.preventDefault();
        modalHeading.current.innerHTML = e.target.innerHTML;
        modalContent.current.innerHTML = e.target.title;
        
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';

        overlay.current.className = 'show';
        popup.current.className = 'show';
    }

    function closePopup(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(overlay.current) overlay.current.className = '';
        if(popup.current) popup.current.className = '';
    }

    // Close popup by clicking anywhere outside of it
    window.onclick = function(event) {
        if (event.target.id === 'overlay') {
            document.body.style.overflow = "auto";
            document.body.style.marginRight = '0';
            if(overlay.current) overlay.current.className = '';
            if(popup.current) popup.current.className = '';
        }
    }


    // Back to top scroll function
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "block";
            }
        } else {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "none";
            }
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    if(state === null || (user && user.role != 'admin')){
        return (
          <div className="main">
            <div className="main-content">
                
                {state === null
                ?
                <>
                <h1 className="mt10">User Details</h1>
                <div className="mt15 center"><h3 style={{fontWeight:'400'}}>No user to display.</h3></div>
                </>
                :
                <div className="mt15 center"><h3 style={{fontWeight:'400'}}>Unauthorized Access</h3></div>
                }
            </div>
          </div>
        )
    } 
    else{
        return (
        <>
        <div className="main">
            <div className="main-content">
                <h1 className="mt10">Add Portal</h1>
        
                <div className="request-header slate-bg mt20" style={{height:'62px'}}>
                    {/* Back to All Users Arrow */}
                    <div className="back-to-users">
                        <Link to="/portals" title="Back to all portals" className="left-angle" />
                    </div>
                </div>
                
                <div className="form-bottom">
                    
                    {/* Customer Number */}
                    <div className="element">
                        <label className="required" title="This should match the PACE Customer #" onClick={event => showModal(event)}>Customer Number</label>
                        <input
                            type="text"
                            ref={refCustomerNumber}
                            name="customer_number"
                            value={state.customer_number}
                            //autoComplete="new-username"
                            onChange={handleChange}
                        />
                        <div ref={refCustomerNumberError} className="errorMessage"></div>
                    </div>

                    {/* Portal Name */}
                    <div className="element">
                        <label className="required" title="Name of the portal" onClick={event => showModal(event)}>Portal Name</label>
                        <input
                            type="text"
                            ref={refName}
                            name="name"
                            value={state.name}
                            //autoComplete="new-password"
                            onChange={handleChange}
                        />
                        <div ref={refNameError} className="errorMessage"></div>
                    </div>
                    
                    {/* JIRA EPIC */}
                    <div className="element">
                        <label className="required" title="Enter the associated JIRA maintenance EPIC" onClick={event => showModal(event)}>JIRA EPIC</label>
                        <input
                            type="text"
                            ref={refEpic}
                            name="epic"
                            value={state.epic}
                            onChange={handleChange}
                        />
                        <div ref={refEpicError} className="errorMessage"></div>
                    </div>

                    {/* Platform */}
                    <div className="element">
                        <label className="required">Platform</label>
                        <select 
                            name="platform"
                            ref={refPlatform}
                            value={state.platform} 
                            onChange={handleChange}
                        >
                            <option>Select an option...</option>
                            <option>uStore</option>
                            <option>PageDNA</option>
                        </select>

                        <div ref={refPlatformError} className="errorMessage">
                            Please select a platform.
                        </div>
                    </div>

                    {/* Status */}
                    <div className="element">
                        <label className="required">Status</label>
                        <select 
                            name="active"
                            ref={refStatus}
                            value={state.active} 
                            onChange={handleChange}
                        >
                            <option>Select an option...</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>

                        <div ref={refStatusError} className="errorMessage">
                            Please select a status.
                        </div>
                    </div>
                </div>

                <div ref={refSubmit} className="mt20 center">
                  <button className="primary-button" onClick={handleSubmit} title="Add Portal">Add</button>
                </div>
            </div>
        </div>

        <div ref={refReturnTop} className="return-top" onClick={topFunction}>
            &#x25B2; Return to top
        </div>

        <div id="overlay" ref={overlay}></div>

        <div id="popup" ref={popup}>
            <h2 id="modal-heading" ref={modalHeading} className="center">Heading</h2>
            <span id="popup-close" ref={modalClose} className="close" onClick={closePopup}>&times;</span>
            <div id="modal-content" ref={modalContent} className="content center">
            Text changed through JS
            </div>
        </div>
        </>
        )
    }
}
