import React, { useState, useRef, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import RequestsTable from '../Components/RequestsTable';
import { UserContext } from '../context/UserContext';
import { dbDate, formatDate, formatISODate, formatSimpleDate, formatDatePicker } from '../Utils/utils'

export default function Requests() {
    const refReturnTop = useRef();
    
    /* Filter Variables */
    const refFilters = useRef();
    const refFilterStartDate = useRef();
    const refFilterStartDateClear = useRef();
    const refFilterEndDate = useRef();
    const refFilterEndDateClear = useRef();
    const refFilterPortal = useRef();
    const refFilterPortalClear = useRef();
    const refFilterAction = useRef();
    const refFilterActionClear = useRef();
    const refFilterStatus = useRef();
    const refFilterStatusClear = useRef();
    const refSearch = useRef();
    const refSearchClear = useRef();
    
    const [ portals, setPortals ] = useState(null)
    /* End of Filter Variables */

    const [ requests, setRequests ] = useState(null)
    const [ original, setOriginal ] = useState(null)
    
    let sessionType = sessionStorage.getItem('filterType')
    const [type, setType] = useState(sessionType !== null && sessionType !== '' ? sessionType : 'mine');
    
    const [confirmDelete, setConfirmDelete] = useState(false);
    
    const { user, setUser } = useContext(UserContext);
    const [ search, setSearch ] = useState('')
    const navigate = useNavigate();

    //Overlay References
    const overlay = useRef();
    const loadingOverlay = useRef();
    const popup = useRef();
    const modalHeading = useRef();
    const modalContent = useRef();
    const modalClose = useRef();
    
    const popupConfirm = useRef();
    const popupConfirmHeading = useRef();
    const popupConfirmContent = useRef();
    const popupConfirmClose = useRef();

    // Search and filter functionality
    async function handleChange(e){
        e.preventDefault();
 
        // Clear Individual Filters
        if(e.target.name === 'clear-portal'){
            refFilterPortal.current.options.selectedIndex = 0;
            refFilterPortalClear.current.style.display = 'none';
            sessionStorage.setItem('requestFilterPortal', '');
        }

        if(e.target.name === 'clear-action'){
            refFilterAction.current.options.selectedIndex = 0;
            refFilterActionClear.current.style.display = 'none';
            sessionStorage.setItem('requestFilterAction', '');
        }

        if(e.target.name === 'clear-start-date'){
            refFilterStartDate.current.value = "";
            refFilterStartDateClear.current.style.display = 'none';
            sessionStorage.setItem('requestFilterStartDate', '');
        }

        if(e.target.name === 'clear-end-date'){
            refFilterEndDate.current.value = "";
            refFilterEndDateClear.current.style.display = 'none';
            sessionStorage.setItem('requestFilterEndDate', '');
        }

        if(e.target.name === 'clear-status'){
            refFilterStatus.current.value = "";
            refFilterStatusClear.current.style.display = 'none';
            sessionStorage.setItem('requestFilterStatus', '');
        }

        // Clear Search
        if(e.target.name === 'clear-search'){
            refSearch.current.value = '';
            refSearchClear.current.style.display = 'none';
            sessionStorage.setItem('requestFilterSearch', '');
            setSearch('');
        }

        // Clear All Filters
        if(e.target.name === 'reset-filters'){
            if(refFilterPortal.current) refFilterPortal.current.options.selectedIndex = 0;
            if(refFilterPortalClear.current) refFilterPortalClear.current.style.display = 'none';
            refFilterAction.current.options.selectedIndex = 0;
            refFilterActionClear.current.style.display = 'none';
            refFilterStartDate.current.value = "";
            refFilterStartDateClear.current.style.display = 'none';
            refFilterEndDate.current.value = "";
            refFilterEndDateClear.current.style.display = 'none';
            refFilterStatus.current.value = "";
            refFilterStatusClear.current.style.display = 'none';
            //refFilterStatus.current.options.selectedIndex = 0;
            refSearch.current.value = '';
            refSearchClear.current.style.display = 'none';
            sessionStorage.setItem('requestFilterPortal', '');
            sessionStorage.setItem('requestFilterAction', '');
            sessionStorage.setItem('requestFilterStartDate', '');
            sessionStorage.setItem('requestFilterEndDate', '');
            sessionStorage.setItem('requestFilterStatus', '');
            sessionStorage.setItem('requestFilterSearch', '');
            setSearch('');
        }

        let results = [...original]

        // Filter on new search box value
        // Update search box
        if(e.target.name === 'new-search'){
            // Adjust width of search box with CSS transition
            if(e.target.value !== ''){
                //refSearch.current.style.width = '250px';
                //refClearSearch.current.style.display = 'inline';
                refSearchClear.current.style.display = 'block';
                sessionStorage.setItem('requestFilterSearch', e.target.value);
            }else{
                //refSearch.current.style.width = '225px';
                //refClearSearch.current.style.display = 'none';
                refSearchClear.current.style.display = 'none';
            }

            if(e.target.value !== ''){
                const value = e.target.value.toLowerCase();
                // Filter by request id, product title, client pn, VC pn
                results = [...results].filter(item => item.name.toLowerCase().indexOf(value) !== -1 || item.id.toString().indexOf(value) !== -1 || (item.partnum !== null && item.partnum.toLowerCase().indexOf(value) !== -1) || (item.vc_product_id !== null && item.vc_product_id.toLowerCase().indexOf(value) !== -1))
            }

            setSearch(e.target.value)
            sessionStorage.setItem('requestFilterSearch', e.target.value)
            
        }
        
        // Filter on existing search box value
        if(e.target.name !== 'new-search' && refSearch.current.value !== ''){
            //console.log('filtering on existing search box value')
            const value = refSearch.current.value;
            sessionStorage.setItem('requestFilterSearch', refSearch.current.value);
            // Filter by request id, product title, client pn, VC pn
            results = [...results].filter(item => item.name.toLowerCase().indexOf(value) !== -1 || item.id.toString().indexOf(value) !== -1 || (item.partnum !== null && item.partnum.toLowerCase().indexOf(value) !== -1) || (item.vc_product_id !== null && item.vc_product_id.toLowerCase().indexOf(value) !== -1))
        }

        // Filter on portal dropdown
        if(refFilterPortal.current){
            if(refFilterPortal.current.selectedIndex > 0){
            results = [...results].filter(item => item.customer_number === refFilterPortal.current.value)
            refFilterPortalClear.current.style.display = 'block';
            sessionStorage.setItem('requestFilterPortal', refFilterPortal.current.value);
            }else{
                refFilterPortalClear.current.style.display = 'none';
            }
        }

        // Filter on action dropdown
        if(refFilterAction.current.selectedIndex > 0){
            //console.log('filtering on action dropdown')
            results = [...results].filter(item => item.action.indexOf(refFilterAction.current.value) !== -1)
            refFilterActionClear.current.style.display = 'block';
            sessionStorage.setItem('requestFilterAction', refFilterAction.current.value);
        }else{
            refFilterActionClear.current.style.display = 'none';
        }

        // Filter on start date
        if(refFilterStartDate.current.value !== ''){
            let year = parseInt(refFilterStartDate.current.value.split('-')[0])
            if(year > 2022 && year < 2042){
                //console.log('Updating start date to ', refFilterStartDate.current.value)
                results = [...results].filter(item => {
                    let date1 = formatISODate(new Date(item.date_submitted))
                    let date2 = refFilterStartDate.current.value;
                    if(date1 >= date2) return item
                })
                refFilterStartDateClear.current.style.display = 'block';
                sessionStorage.setItem('requestFilterStartDate', refFilterStartDate.current.value);
            }
        }else{
            refFilterStartDateClear.current.style.display = 'none';
        }

        // Filter on end date
        if(refFilterEndDate.current.value !== ''){
            let year = parseInt(refFilterEndDate.current.value.split('-')[0])
            if(year > 2022 && year < 2042){
                //console.log('Updating end date to ', refFilterEndDate.current.value)
                results = [...results].filter(item =>  {
                    let date1 = formatISODate(new Date(item.date_submitted))
                    let date2 = refFilterEndDate.current.value;
                    if(date1 <= date2) return item
                });
                refFilterEndDateClear.current.style.display = 'block';
                sessionStorage.setItem('requestFilterEndDate', refFilterEndDate.current.value);
            }
        }else{
            refFilterEndDateClear.current.style.display = 'none';
        }

        // Filter on status dropdown
        if(refFilterStatus.current.selectedIndex > 0){
            console.log('filtering on status dropdown')
            results = [...results].filter(item => item.request_status.indexOf(refFilterStatus.current.value) !== -1)
            refFilterStatusClear.current.style.display = 'block';
            sessionStorage.setItem('requestFilterStatus', refFilterStatus.current.value);
        }else{
            refFilterStatusClear.current.style.display = 'none';
        }

        setRequests(results);
    }
    // End of handleChange

    function showLoading(){
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        loadingOverlay.current.className = 'show';
    }
  
    function hideLoading(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        loadingOverlay.current.className = '';
    }

    useEffect(() => {
        if(confirmDelete) handleDelete()
    }, [confirmDelete]);

    // Pull in MySQL Data - Only once on page load
    useEffect(() => {
        // Verify JWT Token, and then pull data
        const verifyUser = async () => {
            const requestOptions = {
                method: 'POST',
                credentials: 'include',
            };

            let response = await fetch('https://picform.lahlouhonline.com/api/verify', requestOptions);
            let data = await response.json()
            if(data.username){
                // Load Requests
                let requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                };
        
                response = await fetch('https://picform.lahlouhonline.com/api/requests', requestOptions);
                data = await response.json()
                if(data.length > 0){
                    //setRequests(data.filter((item,index) => index <=300));
                    
                    setOriginal(data);

                    let filterPortal = sessionStorage.getItem('requestFilterPortal')
                    let filterAction = sessionStorage.getItem('requestFilterAction')
                    let filterStartDate = sessionStorage.getItem('requestFilterStartDate')
                    let filterEndDate = sessionStorage.getItem('requestFilterEndDate')
                    let filterStatus = sessionStorage.getItem('requestFilterStatus')
                    let filterSearch = sessionStorage.getItem('requestFilterSearch')
                    
                    // Filter on Portal
                    if(filterPortal != '' && filterPortal !== null){
                        data = [...data].filter(item => item.customer_number === filterPortal)
                    }

                    // Filter on Action
                    if(filterAction != '' && filterAction !== null){
                        data = [...data].filter(item => item.action === filterAction)
                    }

                    // Filter on Start Date
                    if(filterStartDate !== null && filterStartDate !== ''){
                        let year = parseInt(filterStartDate.split('-')[0])
                        if(year > 2022 && year < 2042){
                            //console.log('Updating start date to ', refFilterStartDate.current.value)
                            data = [...data].filter(item => {
                                let date1 = formatISODate(new Date(item.date_submitted))
                                let date2 = filterStartDate;
                                if(date1 >= date2) return item
                            })
                        }
                    }

                    // Filter on End Date
                    if(filterEndDate !== null && filterEndDate != ''){
                        let year = parseInt(filterEndDate.split('-')[0])
                        if(year > 2022 && year < 2042){
                            data = [...data].filter(item =>  {
                                let date1 = formatISODate(new Date(item.date_submitted))
                                let date2 = filterEndDate;
                                if(date1 <= date2) return item
                            });
                        }
                    }

                    // Filter on Action
                    if(filterStatus != '' && filterStatus !== null){
                        data = [...data].filter(item => item.request_status === filterStatus)
                    }

                    // Filter on Search
                    if(filterSearch !== null && filterSearch !== ''){
                        let value = filterSearch;
                        data = [...data].filter(item => item.name.toLowerCase().indexOf(value) !== -1 || item.id.toString().indexOf(value) !== -1 || (item.partnum !== null && item.partnum.toLowerCase().indexOf(value) !== -1) || (item.vc_product_id !== null && item.vc_product_id.toLowerCase().indexOf(value) !== -1))
                    }
                    
                    setRequests(data);
                }

                // Load Portal Dropdown
                response = await fetch('https://picform.lahlouhonline.com/api/clients', requestOptions);
                data = await response.json();
                if(data.length > 0){
                    setPortals(data)
                }
            }else{
                await setUser(null)
            }
        }
        
        verifyUser();

    }, [])

    function closePopup(){
        document.body.style.overflow = "auto";
        document.body.style.marginRight = '0';
        if(overlay.current) overlay.current.className = '';
        if(popup.current) popup.current.className = '';
        if(popupConfirm.current) popupConfirm.current.className = '';
    }
    
    // Close popup by clicking anywhere outside of it
    window.onclick = function(event) {
        if (event.target.id === 'overlay') {
            document.body.style.overflow = "auto";
            document.body.style.marginRight = '0';
            if(overlay.current) overlay.current.className = '';
            if(popup.current) popup.current.className = '';
            if(popupConfirm.current) popupConfirm.current.className = '';
        }
    }

    // Back to top scroll function
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "block";
            }
        } else {
            if(refReturnTop.current){
                refReturnTop.current.style.display = "none";
            }
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    async function handleDelete(){
        let count = 0;
        let elements = document.getElementsByName('pending-check');
        for(let a=0;a<elements.length;a++){
            if(elements[a].checked){
                count++;
            }
        }

        popupConfirmHeading.current.innerHTML = 'Confirm Delete';
        if(count === 1){
            popupConfirmContent.current.innerHTML = 'Are you sure you want to delete the selected request?'
        }else if(count > 1){
            popupConfirmContent.current.innerHTML = 'Are you sure you want to delete the selected requests?'
        }

        if(!confirmDelete){
            confirmOverlay();
            return;
        }else{
            const requestOptions = {
                method: 'DELETE',
                credentials: 'include',
            };
            
            let response;
            let data;
            let error = false;
            count = 0;
            let newObj = [...requests]

            for(let a=0;a<elements.length;a++){
                if(elements[a].checked){
                    let id = elements[a].id.split('-')[2]

                    response = await fetch('https://picform.lahlouhonline.com/api/requests/' + id, requestOptions)
                    data = await response.json()

                    if(!data.message){
                        error = true;
                    }else{
                        count++;
                        newObj = newObj.filter(item => item.id != id)
                    }
                }
            }
            
            // Popups and setting state object 
            if(!error){
                // Message and redirect for one request
                if(count === 1){
                    setTimeout(() => {
                        setRequests(newObj)
                        infoModal('Deleted', 'Request has been successfully deleted', true);
                        setConfirmDelete(null);
                    }, 150);
                // Message and redirect for multiple requests
                }else{
                    setTimeout(() => {
                        setRequests(newObj)
                        infoModal('Deleted', 'Requests have been successfully deleted', true);
                        setConfirmDelete(null);
                    }, 150);
                }
            }else{
                if(count === 1){
                    setTimeout(() => {
                        infoModal('Error', 'Request was unable to be deleted', true);
                        setConfirmDelete(null)
                    }, 150);
                }else{
                    setTimeout(() => {
                        infoModal('Error', 'Requests were unable to be deleted', true);
                        setConfirmDelete(null)
                    }, 150);
                }
            }
        }
    }

    // Informational Modal
    function infoModal(heading, content, autoclose = false){
        modalHeading.current.innerHTML = heading;
        modalContent.current.innerHTML = content;
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
        overlay.current.className = 'show';
        popup.current.className = 'show';
        if(autoclose){
            modalClose.current.style.display = 'none';
            setTimeout(() => {
                document.body.style.overflow = "auto";
                document.body.style.marginRight = '0';
                overlay.current.className = '';
                popup.current.className = '';
            }, 2000)
        }else{
            modalClose.current.style.display = 'block';
        }
    }
    
    async function confirmOverlay(){
        let scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.marginRight = scrollbarWidth + 'px';
       
        overlay.current.className = 'show';
        popupConfirm.current.className = 'show';
    }

    return (
        <>
        <div className="main">
            <div className="main-content-wider">
                <h1 className="mt10">Requests</h1>

                {requests && requests !== null && 
                <>
                <div className="mt20 center">
                    Hover over a request status to see additional information on it's meaning.
                </div>

                <div className="filter-results" ref={refFilters}>
                    {portals && portals.length > 0 && (user.role === 'admin' || user.role === 'sales') && 
                    <div>
                        <label>
                            Portal
                        </label>
                        
                        <select 
                            name="filter-portal"
                            className="filter-portals"
                            ref={refFilterPortal}
                            value={sessionStorage.getItem('requestFilterPortal') != '' && sessionStorage.getItem('requestFilterPortal') !== null ? sessionStorage.getItem('requestFilterPortal') : ''} 
                            onChange={handleChange}
                        >
                            <option>Select a portal...</option>
                            {portals.map((item, index) => (<option key={index} value={item.customer_number}>{item.name}</option>))}
                        </select>

                        <a
                            name="clear-portal"
                            className="clear-filter"
                            style={{display:sessionStorage.getItem('requestFilterPortal') != '' && sessionStorage.getItem('requestFilterPortal') !== null ? 'block' : 'none'}}
                            ref={refFilterPortalClear}
                            onClick={handleChange}
                            title="Clear portal"
                        >x</a>
                    </div>
                    }
                    <div>
                        <label>Action</label>
                        <select 
                            name="filter-action"
                            className="filter-action"
                            ref={refFilterAction}
                            //value={state.action}
                            value={sessionStorage.getItem('requestFilterAction') != '' && sessionStorage.getItem('requestFilterAction') !== null ? sessionStorage.getItem('requestFilterAction') : ''} 
                            onChange={handleChange}
                        >
                            <option>Select an action...</option>
                            <option>Add Item</option>
                            <option>Change Item</option>
                            <option>Remove Item</option>
                            <option>Remove & Deactivate</option>
                            <option>Image Update</option>
                        </select>
                        <a
                            name="clear-action"
                            className="clear-filter"
                            style={{display:sessionStorage.getItem('requestFilterAction') != '' && sessionStorage.getItem('requestFilterAction') !== null ? 'block' : 'none'}}
                            ref={refFilterActionClear}
                            onClick={handleChange}
                            title="Clear action"
                        >x</a>
                    </div>
                    <div>
                        <label>Start Date</label>
                        <input
                            type="date"
                            name="filter-start-date"
                            ref={refFilterStartDate}
                            //value={state.filter_from_date}
                            value={sessionStorage.getItem('requestFilterStartDate') != '' && sessionStorage.getItem('requestFilterStartDate') !== null ? sessionStorage.getItem('requestFilterStartDate') : ''} 
                            placeholder="YYYY-MM-DD"
                            onChange={handleChange}
                            className="filter-start-date"
                            onKeyDown={(e) => e.preventDefault()}
                        />
                        <a
                            name="clear-start-date"
                            className="clear-filter"
                            style={{display:sessionStorage.getItem('requestFilterStartDate') != '' && sessionStorage.getItem('requestFilterStartDate') !== null ? 'block' : 'none'}}
                            ref={refFilterStartDateClear}
                            onClick={handleChange}
                            title="Clear start date"
                        >x</a>
                    </div>

                    <div>
                        <label>End Date</label>
                        <input
                            type="date"
                            name="filter-end-date"
                            ref={refFilterEndDate}
                            //value={state.filter_from_date}
                            value={sessionStorage.getItem('requestFilterEndDate') != '' && sessionStorage.getItem('requestFilterEndDate') !== null ? sessionStorage.getItem('requestFilterEndDate') : ''} 
                            placeholder="YYYY-MM-DD"
                            onChange={handleChange}
                            className="filter-end-date"
                            onKeyDown={(e) => e.preventDefault()}
                        />
                        <a
                            name="clear-end-date"
                            className="clear-filter"
                            style={{display:sessionStorage.getItem('requestFilterEndDate') != '' && sessionStorage.getItem('requestFilterEndDate') !== null ? 'block' : 'none'}}
                            ref={refFilterEndDateClear}
                            onClick={handleChange}
                            title="Clear end date"
                        >x</a>
                    </div>

                    <div>
                        <label>Status</label>
                        <select 
                            name="filter-status"
                            className="filter-status"
                            ref={refFilterStatus}
                            //value={state.action}
                            value={sessionStorage.getItem('requestFilterStatus') != '' && sessionStorage.getItem('requestFilterStatus') !== null ? sessionStorage.getItem('requestFilterStatus') : ''} 
                            onChange={handleChange}
                        >
                            <option>Select a status...</option>
                            <option>Received</option>
                            <option>In Progress</option>
                            <option>In Review</option>
                            <option>Fix</option>
                            <option>Completed</option>
                            <option>Cancelled</option>
                        </select>
                        <a
                            name="clear-status"
                            className="clear-filter"
                            style={{display:sessionStorage.getItem('requestFilterStatus') != '' && sessionStorage.getItem('requestFilterStatus') !== null ? 'block' : 'none'}}
                            ref={refFilterStatusClear}
                            onClick={handleChange}
                            title="Clear status"
                        >x</a>
                    </div>

                    <div>
                        <label>Search</label>
                        <input
                            className="filter-search"
                            name="new-search"
                            ref={refSearch}
                            type="text"
                            placeholder="Search by ID, Title or PN"
                            autoComplete="search"
                            //value={search}
                            value={sessionStorage.getItem('requestFilterSearch') != '' && sessionStorage.getItem('requestFilterSearch') !== null ? sessionStorage.getItem('requestFilterSearch') : search} 
                            onChange={handleChange}
                            onFocus={(e) => e.target.placeholder = ""} 
                            onBlur={(e) => e.target.placeholder = "Search by ID, Title or PN"}
                        />
                        
                        <a
                            name="clear-search"
                            className="clear-filter"
                            style={{display:sessionStorage.getItem('requestFilterSearch') != '' && sessionStorage.getItem('requestFilterSearch') !== null ? 'block' : 'none'}}
                            ref={refSearchClear}
                            onClick={handleChange}
                            title="Clear search"
                        >x</a>
                    </div>

                    {/* <div>
                        <label>Status</label>
                        <select 
                            name="filter-status"
                            className="filter-status"
                            ref={refFilterStatus}
                            //value={state.customer_number} 
                            onChange={handleChange}
                        >
                            <option>Select a status...</option>
                            <option>Pending</option>
                            <option>In Review</option>
                            <option>Received</option>
                            <option>Fix</option>
                            <option>In Progress</option>
                            <option>Completed</option>
                            <option>Cancelled</option>
                        </select>
                    </div> */}

                    <div>
                        <button
                            name="reset-filters"
                            className="filter-reset-button"
                            onClick={handleChange}
                            title="Clear all filters"
                        >Clear All</button>
                    </div>
                </div>

                </>
                }
                {requests != null && requests.length > 0 && 
                    <RequestsTable 
                        requests={requests} 
                        handleDelete={handleDelete} 
                        showLoading={showLoading} 
                        hideLoading={hideLoading} 
                        infoModal={infoModal}
                        type={type}
                        setType={setType}
                        //filtersActive={refFilterPortal.current && refFilterPortal.current.selectedIndex === 0 && refFilterAction.current && refFilterAction.current.selectedIndex === 0 && refFilterStartDate.current && refFilterStartDate.current.value === "" && refFilterEndDate.current && refFilterEndDate.current.value === "" && refSearch.current && refSearch.current.value === "" ? false : true}
                    />
                }
                {requests && requests.length === 0 && 
                <div className="no-results">
                    No results found for the current search.
                </div>
                }
            </div>
        </div>

        <div ref={refReturnTop} className="return-top" onClick={topFunction}>
            &#x25B2; Return to top
        </div>

        <div id="overlay" ref={overlay}></div>

        <div 
            id="loading-overlay" 
            ref={loadingOverlay} 
            className={requests && requests.length >= 0 ? "" : "show"}
        >
            <div className="deadCenter">
                <div className="lds-spinner">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
            </div>
        </div>

        <div id="popup" ref={popup}>
            <h2 id="modal-heading" className="center" ref={modalHeading}></h2>
            <span id="popup-close" ref={modalClose} className="close" onClick={closePopup}>&times;</span>
            <div id="modal-content" ref={modalContent} className="content center"></div>
        </div>

        <div id="popup-confirm" ref={popupConfirm}>
            <h2 id="modal-heading" ref={popupConfirmHeading} style={{textAlign:'center'}}>Are You Sure?</h2>
            <span className="close" ref={popupConfirmClose} onClick={() => {closePopup();setConfirmDelete(null)}}>&times;</span>
            <div id="modal-content" ref={popupConfirmContent} className="content center">
                Text changed through JS
            </div>
            <div className="mt10 center">
            <button className="primary-button smaller-button" onClick={(e) => {closePopup();overlay.current.className = 'show';setConfirmDelete(true);}} title="Yes">Yes</button>
            <button className="secondary-button smaller-button" onClick={() => {closePopup();setConfirmDelete(false);}} title="No">No</button>
            </div>
        </div>
        </>
    )
}