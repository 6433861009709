import React, { useState, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../context/UserContext';
import { formatDate, formatDatePicker, addBusinessDays, dbDate } from '../Utils/utils'

export default function RequestsTable(props) {
    const { requests, handleDelete, showLoading, hideLoading, infoModal, type, setType } = props;
    let sortKey = sessionStorage.getItem('requestSortKey');
    let sortDir = sessionStorage.getItem('requestSortDir');
    const [sortConfig, setSortConfig] = useState({key: sortKey !== '' && sortKey !== null ? sortKey : 'date_submitted', direction: sortDir !== '' && sortDir !== null ? sortDir : 'descending'});
    //const [sortConfig, setSortConfig] = useState({key: 'date_submitted', direction: 'descending'});
    const navigate = useNavigate();

    const refAllPortals = useRef();
    const refMyPortals = useRef();

    const refSubmit = useRef();
    const refDelete = useRef();
    const refCheckAll = useRef();
    const refCheckAllContainer = useRef();
    const refSubmitError = useRef();

    const { user } = useContext(UserContext);
  
    async function handleSubmit(){
        let shouldSubmit = true;
        let submitTeams = true;

        // Hide Submit Error
        refSubmitError.current.classList.remove('show');
        
        // Process pending requests table
        let portals = [];
        let count = 0;
        let cust_num;
        let effDates = [];
        const checkedRequests = [];
        for(let a=0;a<pendingRequests.length;a++){
            if(document.getElementById('pending-check-' + pendingRequests[a].id).checked){
                portals.push(pendingRequests[a].company)
                effDates.push(pendingRequests[a].effective_date)
                count++;
                cust_num = pendingRequests[a].customer_number;
                checkedRequests.push(pendingRequests[a])
            }
        }

        // Check for duplicate part numbers in pending requests
        let dupes;
        for(let a=0;a<checkedRequests.length;a++){
            dupes = requests.filter(item => item.partnum === checkedRequests[a].partnum && (item.request_status === 'Received' || item.request_status === 'Fix' || item.request_status === 'In Progress'));
            if(dupes.length > 0){
                shouldSubmit = false;
                //infoModal("Duplicate", "One or more selected part numbers is already associated with an open request")
                refSubmitError.current.innerHTML = 'One or more selected part numbers is already associated with an open request';
                refSubmitError.current.classList.add('show');
                break;
            }
        }

        // Put earliest effective date first
        // To attach to Master request
        effDates.sort();

        // Check for mismatched portals in pending requests
        if(portals.length > 1){
            let firstPortal = portals[0];
            for(let a=0;a<portals.length;a++){
                if(portals[a] != firstPortal){
                    shouldSubmit = false;
                    refSubmitError.current.innerHTML = 'You may only submit requests from one portal at a time';
                    refSubmitError.current.classList.add('show');
                    break;
                }
            }
        }
        // End of processing pending requests table

        // Check for effective dates that are less than one business day in the future
        // If they are one business day in the future, make sure the next day checkbox is selected
        // Do not run for external (effective date is not required)
        if(user.role !== 'external'){
            let todaysDate, eDate, newDate;
            for(let a=0;a<pendingRequests.length;a++){
                if(document.getElementById('pending-check-' + pendingRequests[a].id).checked){
                    todaysDate = new Date();

                    // Grab entered effective date at midnight
                    eDate = new Date(pendingRequests[a].effective_date)
                    eDate.setUTCHours(0,0,0,0);
                    
                    // Add 1 business day to today's date
                    const newDate = new Date(addBusinessDays(todaysDate, 1));
                    newDate.setUTCHours(0,0,0,0);

                    // Date is today or earlier
                    if(eDate.getTime() < newDate.getTime()){
                        refSubmitError.current.innerHTML = 'All selected requests must use a date at least 1 business day in the future.';
                        refSubmitError.current.classList.add('show');
                        shouldSubmit = false;
                        break;
                    }
                }
            }
        }

        if(shouldSubmit){
            let requestObj;
            let requestOptions;
            let data;
            let response;
            let masterId;

            let action;
            let customerNumber;

            showLoading();
            
            /////////////////////////////////
            // Create Master Group Request
            /////////////////////////////////
            if(count > 1){
                let requestObj={
                    action: "Multiple",
                    request_status: "",
                    submitter_email: user.username,
                    name: count + " items",
                    customer_number: cust_num,
                    effective_date:effDates[0],
                    group_request: "master"
                }
               
                // Submit to PICDB
                requestOptions = {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(requestObj)
                };
                
                response = await fetch('https://picform.lahlouhonline.com/api/requests', requestOptions)
                            
                if(response.status === 403){
                    hideLoading();
                    infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
                }else{
                    data = await response.json()

                    if(!data.name){
                        submitTeams = false;
                    }else{
                        masterId = data.id;
                    }
                }
            }
            // End of creating master group request

            // Update status on all slave requests from Pending to In Review or Received
            if(count >= 1){
                if(user.role === 'external'){
                    requestObj={
                        request_status: "In Review"
                    }
                }else{
                    requestObj={
                        request_status: "Received"
                    }
                }
            }

            /////////////////////////////////
            // Create Slave Requests
            /////////////////////////////////
            let teamRequests = [];
            let num = 1;
            for(let a=0;a<pendingRequests.length;a++){
                if(document.getElementById('pending-check-' + pendingRequests[a].id).checked){
                    // Set position of slave requests
                    if(count > 1){
                        requestObj.group_request = masterId + '-' + num;
                        num++;
                    }

                    // Patch Request to PICDB to change status = In Review or Received
                    requestOptions = {
                        method: 'PATCH',
                        credentials: 'include',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(requestObj)
                    };
                    
                    response = await fetch('https://picform.lahlouhonline.com/api/requests/' + pendingRequests[a].id, requestOptions)
                    
                    if(response.status === 403){
                        hideLoading();
                        infoModal("You've been logged out", "You must be logged in to complete this action. Please refresh the page and log back in.")
                    }else{
                        data = await response.json()
                        
                        if(!data.name){
                            submitTeams = false;
                        }else{
                            // Set Teams Data
                            customerNumber = pendingRequests[a].customer_number;
                            teamRequests.push(data);
                        }
                    }
                }
            }

            // No errors adding master and/or associated requests - Continue On...
            if(submitTeams){
                let companyName;

                // Grab Company Name from Clients Table
                let requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                };

                let response = await fetch('https://picform.lahlouhonline.com/api/client/' + customerNumber, requestOptions)
                let data = await response.json();
                if(!data.error){
                    companyName = data.name;
                }

                let teamsTitle = 'Portal Item Change Request';
                let teamsType = 'New Request';
                let teamsText = "https://picform.lahlouhonline.com/request?id=" + teamRequests[0].id;
                let teamsDate = teamRequests[0].effective_date;
                action = teamRequests[0].action;
                
                if(count > 1){
                    teamsTitle = 'Portal Item Change Group Request';
                    teamsType = 'New Group Request';
                    teamsText = "First Item:   \n";
                    action = "Multiple";

                    for(let a=0;a<teamRequests.length;a++){
                        if(teamRequests[a].group_request.split('-')[1] === '1'){
                            teamsText += "https://picform.lahlouhonline.com/request?id=" + teamRequests[a].id;
                            teamsDate = teamRequests[a].effective_date;
                            break;
                        }
                    }
                }

                if(companyName.indexOf('Test Portal') !== -1){
                    teamsTitle = 'Portal Item Change Request (Test)';
                    teamsType = 'Test - Do not process';
                }
                
                // Teams Message
                const teamsObj =
                {
                    "@context": "https://schema.org/extensions",
                    "@type": "MessageCard",
                    "themeColor": "0076D7",
                    "summary": "Summary",
                    "sections": [
                        {
                            "activityTitle": teamsTitle,
                            "facts": [
                                {
                                    "name": "Type:",
                                    "value": teamsType
                                },
                                {
                                    "name": "Action:",
                                    "value": action
                                },
                                {
                                    "name": "Company:",
                                    "value": companyName
                                },
                                {
                                    "name": "Submitted By:",
                                    "value": user.username
                                },
                                {
                                    "name": "Role:",
                                    "value": user.role
                                },
                                {
                                    "name": "Effective Date:",
                                    "value": formatDate(new Date(teamsDate))
                                }
                            ],
                            "text": teamsText
                        }
                    ]
                }

                // Send to Teams
                requestOptions = {
                    method: 'POST',
                    mode: 'no-cors',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(teamsObj)
                };
            

                if(companyName.indexOf('Test Portal') === -1){
                    fetch('https://lahlouhinc.webhook.office.com/webhookb2/a51eee01-4ded-4410-ae26-8ecd3f547d46@55d9408b-9060-4745-9c50-44dba74d1b39/IncomingWebhook/c7cb6c6f43bc44a69ce761f64df16b3b/b11fe407-83f0-4667-96ae-7740b0ea7b21', requestOptions)
                }
                
                requestOptions = {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json'}
                };

                // Send Single Request Email
                if(count === 1){
                    response = await fetch('https://picform.lahlouhonline.com/api/single-email-submit/' + teamRequests[0].id, requestOptions) 
                // Send Group Request Email
                }else{
                    response = await fetch('https://picform.lahlouhonline.com/api/group-email-submit/' + masterId, requestOptions)
                }

                //data = await response.json();

                hideLoading();

                if(count === 1){
                    navigate('/thankyou', { state: { type: 'request' } });
                }else{
                    navigate('/thankyou', { state: { type: 'group' } });
                }
            }
        }
    }
    // End of handleSubmit

    let sortedRequests = [...requests].filter(item => item.request_status !== 'Pending' && item.group_request !== 'master');
    let pendingRequests = [...requests].filter(item => item.request_status === 'Pending' && item.group_request !== 'master' && item.submitter_email === user.username);

    /////////////////////////////
    // Column sorting
    // Resets on page load
    /////////////////////////////
    sortedRequests.sort((a, b) => {
        // Numeric Sort
        if(sortConfig.key === 'id'){
            a = a[sortConfig.key]
            b = b[sortConfig.key]
        // Alphanumeric Sort
        }else{
            if(a[sortConfig.key] === null) console.log(a.company)
            a = a[sortConfig.key].toLowerCase()
            b = b[sortConfig.key].toLowerCase()
        }

        // Ascending Sort
        if(sortConfig.direction === 'ascending'){
            if (a < b) return -1;
            if(a > b) return 1;
            return 0;
        // Descending Sort
        }else{
            if (a < b) return 1;
            if(a > b) return -1;
            return 0;
        }
    });


    //////////////////////
    // My Portals    
    //////////////////////
    //let showMine = false;
    // Only display 'My Portals' for Sales
    //if(user.role === 'sales'){
        //showMine = [...sortedRequests].filter(item => item.request_status !== 'Pending' && user.customer_number.split(',').indexOf(item.customer_number) != -1).length > 0;  
        //if(type === 'mine' && showMine){
        //if(type === 'mine'){
            //sortedRequests = [...sortedRequests].filter(item => item.request_status !== 'Pending' && item.group_request !== 'master' && user.customer_number.split(',').indexOf(item.customer_number) != -1);
        //}

    //}

    // Show my portals
    if(user.role === 'sales' && type === 'mine'){
        sortedRequests = [...sortedRequests].filter(item => item.request_status !== 'Pending' && item.group_request !== 'master' && user.customer_number.split(',').indexOf(item.customer_number) != -1);
    }
    
    const requestSort = (key, type) => {
        let direction = 'ascending';
        
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        setSortConfig({ key, direction, type });
        sessionStorage.setItem('requestSortKey', key);
        sessionStorage.setItem('requestSortDir', direction);
    }

    function setArrow(str){
        if(sortConfig.key === str){
            if(sortConfig.direction === 'ascending'){
                return 'arrow-up'
            }else{
                return 'arrow-down';
            }
        }else{
            return null
        }
    }

    //Checkbox Click (Group Request)
    function handleChange(e){
        //refSubmitError.current.style.display = 'none';
        refSubmitError.current.classList.remove('show');

        let count = 0;

        for(let a=0;a<pendingRequests.length;a++){
            if(document.getElementById('pending-check-' + pendingRequests[a].id).checked){
                count++;
            }
        }

        if(count === 0){
            refSubmit.current.disabled = true;
            refSubmit.current.title = '';
            refDelete.current.disabled = true;
            refDelete.current.title = '';
        }else{
            refSubmit.current.disabled = false;
            refSubmit.current.onclick=function(){handleSubmit()}
            refDelete.current.disabled = false;
            refDelete.current.onclick=function(){handleDelete()}
        }
        
        if(count === 1){
            refSubmit.current.title = 'Submit selected request'
            refDelete.current.title = 'Delete selected request'
        }
        if(count > 1){
            refSubmit.current.title = 'Submit selected requests'
            refDelete.current.title = 'Delete selected requests'
        }

        if(count < pendingRequests.length){
            refCheckAll.current.checked = false;
            refCheckAllContainer.current.title = 'Select all items';
        }

        if(count === pendingRequests.length){
            refCheckAll.current.checked = true;
            refCheckAllContainer.current.title = 'Unselect all items';
        }
    }

    function rowClick(e){
        // Clicked on the edit icon or status box
        if(e.target.className === 'edit-icon' || e.target.className.indexOf('highlight') !== -1){
            navigate('/request?id=' + e.target.parentNode.parentNode.getAttribute('data-id'))
        // Clicked on the trash icon (not currently being used)
        }else if (e.target.className === 'trash-icon'){
        // Clicked on the checkbox
        }else if (e.target.type === 'checkbox' || e.target.className === 'checkbox-container' || e.target.className === 'checkmark'){
        }
        // Clicked anywhere else on the table row
        else{
            if(e.target.parentNode.parentNode.getAttribute('data-master')){
                navigate('/request?id=' + e.target.parentNode.parentNode.getAttribute('data-id'))
            }else{
                navigate('/request?id=' + e.target.parentNode.getAttribute('data-id'))  
            }
        }
    }

    function checkAll(){
        //refSubmitError.current.style.display = 'none';
        refSubmitError.current.classList.remove('show');
        
        if(refCheckAll.current.checked){
            for(let a=0;a<pendingRequests.length;a++){
                document.getElementById('pending-check-' + pendingRequests[a].id).checked = true;
            }
            refCheckAllContainer.current.title = 'Unselect all items'
            refSubmit.current.disabled = false;
            refSubmit.current.onclick=function(){handleSubmit()}
            refDelete.current.disabled = false;
            refDelete.current.onclick=function(){handleDelete()}
        }else{
            for(let a=0;a<pendingRequests.length;a++){
                document.getElementById('pending-check-' + pendingRequests[a].id).checked = false;
            }
            refCheckAllContainer.current.title = 'Select all items'
            refSubmit.current.disabled = true;
            refSubmit.current.title = '';
            refDelete.current.disabled = true;
            refDelete.current.title = '';
        }
    }

    function changeType(str){
        if(str === 'mine'){
            setType('mine');
            sessionStorage.setItem('filterType', 'mine')
            if(refMyPortals.current){
                refMyPortals.current.classList.add('active');
                refMyPortals.current.title = '';
            }
            if(refAllPortals.current){
                refAllPortals.current.classList.remove('active');
            }
        }else{
            setType('all');
            sessionStorage.setItem('filterType', 'all')
            if(refMyPortals.current){
                refMyPortals.current.classList.remove('active');
            }
            if(refAllPortals.current){
                refAllPortals.current.classList.add('active')
                refAllPortals.current.title = '';
            }
        }
    }

    let pendingStyle, availStyle = '';
    if(user.role === 'admin' || user.role === 'sales'){
        pendingStyle = 'internal-pending-table mt10';
        availStyle = 'internal-request-table mt10';
    }else{
        pendingStyle = 'external-pending-table mt10';
        availStyle = 'external-request-table mt10'
    }

    return (
        <>
        {pendingRequests && pendingRequests.length > 0 &&
        <>
        <h2 className="table-width mt30" style={{textAlign:'center'}}>Pending Requests</h2>

        <div className="mt10 center">
            Please select the request(s) you would like to submit or delete.
        </div>
        
        <table className={pendingStyle} cellPadding="0" cellSpacing="0">
        <thead>
            <tr>
                <th>
                    <label ref={refCheckAllContainer} className="checkbox-container" title="Select all items">
                        <input 
                            type="checkbox"
                            name="check-all"
                            ref={refCheckAll}
                            id='check-all'
                            onChange={checkAll}
                            //value="yes"
                        />
                        <span className="checkmark"></span>
                    </label>
                </th>
                {(user.role === 'admin' || user.role === 'sales') && <th>Portal</th>}
                <th>Action</th>
                <th>Product Title</th>
                <th>Effective</th>
                <th>Saved</th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {pendingRequests.map(request => {
                return (
                <tr key={request.id} data-id={request.id} title="Click to view/edit request" onClick={rowClick}>
                    <td>
                        <label className="checkbox-container">
                            <input 
                                type="checkbox"
                                name="pending-check"
                                id={"pending-check-" + request.id}
                                //defaultChecked={state.supplied_image === 'yes'}
                                onChange={handleChange}
                                value="yes"
                            />
                            <span className="checkmark"></span>
                        </label>
                    </td>
                    
                    {(user.role === 'admin' || user.role === 'sales') && <td data-label="Portal">{request.company}</td>}
                    <td data-label="Action">{request.action}</td>
                    <td data-label="Name">{request.name}</td>
                    <td data-label="Effective">{request.effective_date == null ? "" : formatDatePicker(new Date(request.effective_date))}</td>
                    <td data-label="Saved">{formatDate(new Date(request.date_submitted))}</td>
                    <td data-label="Status">
                        <span className="highlight dark-draft" title="Request is ready to be reviewed and submitted">Pending</span>                    
                    </td>
                    <td>
                        <div className="edit-icon" title="Edit request" onClick={() => navigate('/request?id=' + request.id)}></div>
                        {/* <div className="trash-icon" onClick={() => singleDelete(request.id)} title="Delete request"></div> */}
                    </td>
                </tr>
                )    
            })}
        </tbody>
        </table>

        {/* Submit Error */}
        <div ref={refSubmitError} className="small-width center errorMaster"></div>

        {/* Submit and delete buttons */}
        <div className="pending-buttons">
            <button 
                ref={refSubmit} 
                className="primary-button" 
                disabled={true}
                onClick={handleSubmit}
            >Submit</button>

            <button 
                ref={refDelete} 
                className="reject-button" 
                disabled={true}
            >Delete</button>
        </div>
        </>
        }


        {pendingRequests && pendingRequests.length > 0 && sortedRequests && sortedRequests.length > 0 && 
        <h2 className="table-width mt40" style={{textAlign:'center'}}>All Requests</h2>
        }

        {user.customer_number.split(',')[0] !== '1' && user.role === 'sales' && 
        <div className={pendingRequests && pendingRequests.length === 0 ? "change-portal-type mt30" : "change-portal-type mt20"}>
            <a 
                ref={refMyPortals} 
                title={type === 'mine' ? "" : "View my portals"}
                className={type ==='mine' ? "active" : "" }
                onClick={type === 'all' ? (() => changeType('mine')) : null}
            >My Portals</a>
            <a 
                ref={refAllPortals} 
                title={type === 'all' ? "" : "View all portals"}
                className={type === 'all' ? "active" : ""}
                onClick={type === 'mine' ? (() => changeType('all')) : null}
            >All Portals</a>
        </div>
        }

        {sortedRequests && sortedRequests.length === 0 && pendingRequests && pendingRequests.length === 0 && 
        <div className="no-results">
            No results found for the current search.
            {user.role === 'sales' && refMyPortals.current && refMyPortals.current.classList.contains('active') !== -1 &&
            <div className="mt15" style={{fontSize:'16px'}}>
                <b style={{fontWeight:'500',lineHeight:'30px'}}>Tip: </b>You are currently only viewing the portals assigned to your profile.<br/>
                Try switching to 'All Portals' if you need a more complete view.
            </div>
            }
        </div>
        }

        
        {/* All Requests Table */}
        {sortedRequests && sortedRequests.length > 0 && 
        <>
        <div className="results-number"><span>{sortedRequests.length.toLocaleString('en', {useGrouping:true})}</span> {sortedRequests.length === 1 ? 'result' : 'results'}</div>

        <table className={availStyle} cellSpacing="0" cellPadding="0">
        <thead>
            <tr>
                {/* ID Column */}
                <th 
                  onClick={() => {requestSort('id', 'all')}}
                  title="Sort by Request ID"
                >
                    ID 
                    <span 
                        className={setArrow('id')}
                    ></span>
                </th>

                {/* Portal Column */}
                {(user.role === 'admin' || user.role === 'sales') &&
                <th 
                    onClick={() => {requestSort('company', 'all')}} 
                    title="Sort by Portal"
                >
                    Portal 
                    <span 
                        className={setArrow('company')}
                    ></span>
                </th>
                }
                
                {/* Action Column */}
                <th
                    onClick={() => {requestSort('action', 'all')}}
                    title="Sort by Action"
                >
                    Action 
                    <span 
                        className={setArrow('action')}
                    ></span>
                </th>
                
                {/* Product Title Column */}
                <th
                    onClick={() => {requestSort('name', 'all')}}
                    title="Sort by Product Title"
                >
                    Product Title 
                    <span 
                        className={setArrow('name')}
                    ></span>
                </th>

                {/* Effective Date Column */}
                <th 
                    onClick={() => {requestSort('effective_date', 'all')}}
                    title="Sort by Effective Date"
                >
                    Effective 
                    <span 
                        className={setArrow('effective_date')}
                    ></span>
                </th>
                
                {/* Date Submitted Column */}
                <th 
                    onClick={() => {requestSort('date_submitted', 'all')}}
                    title="Sort by Submission Date"
                >
                    Submitted 
                    <span 
                        className={setArrow('date_submitted')}
                    ></span>
                </th>
                
                {/* Request Status Column */}
                <th
                    onClick={() => {requestSort('request_status', 'all')}}
                    title="Sort by Request Status"
                >
                    Status 
                    <span 
                        className={setArrow('request_status')}
                    ></span>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {sortedRequests.map(request => {
                return (
                <tr 
                    key={request.id} 
                    data-id={request.id} 
                    title="Click to view request" 
                    onClick={rowClick} 
                    //className={request.group_request !== null && request.group_request !== '' ? 'slave-highlight active' : undefined}
                    //className={getHighlight(request.group_request)}
                >
                    <td data-label="ID">
                        {request.id}
                    </td>
                    {(user.role === 'admin' || user.role === 'sales') && <td data-label="Portal">{request.company}</td>}
                    <td data-label="Action">{request.action}</td>
                    <td data-label="Name">
                        {request.name}
                    </td>
                    <td data-label="Effective">{formatDatePicker(new Date(request.effective_date))}</td>
                    <td data-label="Submitted">
                        {formatDate(new Date(request.date_submitted))}<br/>
                    </td>
                    <td data-label="Status">
                    {request.request_status === 'In Review' && <span className="highlight in-review" title="Request is being reviewed by Lahlouh">{request.request_status}</span>}
                        {request.request_status === 'In Progress' && <span className="highlight in-progress" title="Request is actively being worked on. Next status update will be flagged as 'Completed'">{request.request_status}</span>}
                        {request.request_status === 'Fix' && <span className="highlight fix" title="Request has items for the submitter to fix">{request.request_status}</span>}
                        {request.request_status === 'Received' && <span className="highlight received" title="Request has been received and is pending approval">{request.request_status}</span>}
                        {request.request_status === 'Completed' && <span className="highlight completed" title="Request has been completed and is in production">{request.request_status}</span>}
                        {request.request_status === 'Cancelled' && <span className="highlight cancelled" title="Request has been cancelled and will not be processed">{request.request_status}</span>}
                    </td>
                    <td>
                        <div className="edit-icon" title="Edit request"></div>
                    </td>
                </tr>
                )
            })}
        </tbody>
        </table>
        </>
        }
        </>
    )
}

